import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from 'react-table';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { TABLE_PAGINATION, PURCHASEHISTORY } from '@constants';

const StyledTr = styled.tr`
  background-color: #fff;
  &:hover {
    background-color: #f9fbff;
  }
`;

function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: any;
  setGlobalFilter: any;
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(VALUE => {
    setGlobalFilter(VALUE || undefined);
  }, 200);

  return (
    <span>
      <input
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={t('translation.NOTICE_SEARCH_INPUT_PLACEHOLDER')}
        style={{
          padding: '20px 11px',
          width: '360px',
          height: '50px',
          backgroundColor: '#fff',
          fontSize: '18px',
          border: '1px solid #CECECE',
          outline: 'none',
        }}
      />
    </span>
  );
}

interface PurchaseTable {
  no: string;
  service: string;
  keyword: string;
  type: string;
  point: string;
  date: string;
  basedate: string;
  lookup: string;
  save: string;
}
interface Props {
  columns: any;
  data: any;
  onView: (viewKey: any) => void;
  onSave: (saveKey: any) => void;
}

export const Table = ({ columns, data, onView, onSave }: Props) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable<PurchaseTable>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  useEffect(() => {
    setPageSize(15);
  }, []);

  const addedPage = Math.floor(pageIndex / 10) * 10;
  const shownPages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    .map(pageNum => pageNum + addedPage)
    .filter(pageNum => pageNum <= pageCount);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            marginBottom: '20px',
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {t('translation.TOTAL_PURCHASE_DATA')} : {rows.length}
          {t('translation.UNIT_COUNT2')}
        </div>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <table
        {...getTableProps()}
        key={nanoid()}
        style={{
          position: 'relative',
          margin: '0 auto',
          width: '1420px',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={nanoid()}
              style={{
                height: '40px',
              }}
            >
              {headerGroup.headers.map((column, i) => {
                const widths = [
                  '80px',
                  '222px',
                  '240px',
                  '160px',
                  '160px',
                  '185px',
                  '185px',
                  '128px',
                ];
                const width = widths[i];
                return (
                  <th
                    {...column.getHeaderProps()}
                    key={nanoid()}
                    style={{
                      width,
                      background: '#F2F2F2',
                      color: '#555555',
                      fontWeight: 'bold',
                    }}
                  >
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <StyledTr
                {...row.getRowProps()}
                key={nanoid()}
                style={{
                  height: '40px',
                  borderBottom: '1px dashed #E8E8E8',
                }}
              >
                {row.cells.map((cell: any, i: number) => {
                  if (
                    i === 7 &&
                    cell.render('Cell') &&
                    cell.row.original.type === t('translation.TABLE_TYPE_TYPE1')
                  ) {
                    return (
                      <td
                        key={nanoid()}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {cell.row.original.status === 1 ? (
                            <button
                              type="button"
                              style={{
                                width: '24px',
                                height: '24px',
                                background: 0,
                                border: 0,
                                cursor: 'pointer',
                              }}
                              onClick={() => onView(cell.row.original.viewKey)}
                            >
                              <img
                                src={PURCHASEHISTORY.view}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                                alt="view"
                              />
                            </button>
                          ) : (
                            <button
                              type="button"
                              style={{
                                width: '24px',
                                height: '24px',
                                background: 0,
                                border: 0,
                                cursor: 'not-allowed',
                              }}
                            >
                              <img
                                src={PURCHASEHISTORY.disabledView}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                                alt="save"
                              />
                            </button>
                          )}
                        </div>
                      </td>
                    );
                  }
                  if (
                    i === 7 &&
                    cell.render('Cell') &&
                    cell.row.original.type === t('translation.TABLE_TYPE_TYPE2')
                  ) {
                    return (
                      <td
                        key={nanoid()}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {cell.row.original.status === 1 ? (
                            <button
                              type="button"
                              style={{
                                width: '24px',
                                height: '24px',
                                background: 0,
                                border: 0,
                                cursor: 'pointer',
                              }}
                              onClick={() => onSave(cell.row.original.saveKey)}
                            >
                              <img
                                src={PURCHASEHISTORY.save}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                                alt="save"
                              />
                            </button>
                          ) : (
                            <button
                              type="button"
                              style={{
                                width: '24px',
                                height: '24px',
                                background: 0,
                                border: 0,
                                cursor: 'not-allowed',
                              }}
                            >
                              <img
                                src={PURCHASEHISTORY.disabledSave}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                                alt="save"
                              />
                            </button>
                          )}
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={nanoid()}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {cell.render('Cell')}
                      {i === 3 && ` / ${cell.row.original.recipient}`}
                      {i === 4 ? 'P' : ''}
                    </td>
                  );
                })}
              </StyledTr>
            );
          })}
        </tbody>
      </table>
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          fontSize: '14px',
        }}
      >
        Per Page
        <select
          style={{
            marginLeft: '10px',
            width: '60px',
            height: '30px',
            borderColor: '#cecece',
            fontSize: '14px',
          }}
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[15, 25, 50, 100].map(PAGESIZE => (
            <option key={PAGESIZE} value={PAGESIZE}>
              {PAGESIZE}
            </option>
          ))}
        </select>
      </div>
      <div
        className="pagination"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <button
          type="button"
          style={{
            marginRight: '6px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => gotoPage(0)}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.gotofirst}
            alt="go to first"
          />
        </button>{' '}
        <button
          type="button"
          style={{
            marginRight: '20px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => previousPage()}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.prev}
            alt="prev"
          />
        </button>
        {shownPages.map((pageNum, i) => {
          return (
            <button
              type="button"
              key={nanoid()}
              style={{
                marginRight: i === shownPages.length - 1 ? 0 : '2px',
                width: '24px',
                height: '24px',
                border: 0,
                borderRadius: '5px',
                background: pageNum === pageIndex + 1 ? '#555555' : 0,
                fontSize: '14px',
                color: pageNum === pageIndex + 1 ? '#fff' : '#000',
                cursor: 'pointer',
              }}
              onClick={() => gotoPage(pageNum - 1)}
            >
              {pageNum}
            </button>
          );
        })}
        <button
          type="button"
          style={{
            marginLeft: '20px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => nextPage()}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.next}
            alt="next"
          />
        </button>{' '}
        <button
          type="button"
          style={{
            marginLeft: '6px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => gotoPage(pageCount - 1)}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.gotolast}
            alt="go to last"
          />
        </button>{' '}
      </div>
    </>
  );
};
