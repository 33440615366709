import styled from 'styled-components';

export const PublicInput = styled.input`
  outline: none;
  border: none;
  border: 1px solid #cecece;
  color: #000;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a1a1;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a1a1a1;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #a1a1a1;
  }
`;
