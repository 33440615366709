import styled from 'styled-components';

export const Contents = styled.div`
  padding: 20px;
  width: 1200px;
  background-color: #fff;
  & > div:first-child {
    margin-bottom: 50px;
  }
`;

export const Section = styled.div``;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #000;
`;
export const SectionDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #e8e8e8;
`;
export const SectionDetailTitle = styled.div``;
