import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { onConvertPopularPostingExposureSuitability } from 'common/functions';
import { Gap, FlexContainer } from '@atoms/utils';
import { BasicTable } from '@atoms/tables';
import { useTypedSelector, RootState } from '@features/rootStore';
import { PublicBoxWithTitle, SmallPublicBoxWithIcon } from '@molecules/boxes';
import {
  tableCodes as CODE,
  SMALLPUBLICBOX_ICONS as SMALLBOXICON,
} from '@constants';
import { PublicText } from '@atoms/texts';
import { PublicBox } from '@atoms/boxes';
import AreaChart from '@atoms/apexcharts/AreaChart';
import { BarChart } from '@atoms/apexcharts';
import {
  ExposedHashtags,
  AccountSuitability,
  DetailSuitability,
  NoDataCase,
} from '@pages/Account/components';
import { convertNumberNotation, addCommaToNumber } from '@utils';
import { RechartLineChart } from '@atoms/recharts';

const dateInfo: BasicInfoSet = {
  iconName: SMALLBOXICON.account_post,
  label: '조회 날짜',
  width: 480,
  height: 110,
};

const chargedItemTableFrame: TableSet = {
  tableCode: CODE.SF,
  title: '단어포함 해시태그',
  columns: ['NO', '수집기준', '수집 해시태그', '게시물 수'],
};

export const Ordered = () => {
  const { t } = useTranslation();
  const { orderedItem } = useTypedSelector(({ rootReducer }: RootState) => ({
    orderedItem: rootReducer.serviceReducers.orderedItem,
  }));

  if (orderedItem.serviceCode === 103) {
    const followValues: number[] = [];
    const followDates: string[] = [];
    const postValues: number[] = [];
    const postDates: string[] = [];
    const followerValues: number[] = [];
    const followerDates: string[] = [];
    const like_averageValues: number[] = [];
    const like_averageDates: string[] = [];
    const {
      search_date,
      info,
      follow,
      post,
      follower,
      like_average,
      exposed_hashtag,
      suitable_info,
    } = orderedItem;
    const accountBasicInfo = {
      updateDate: search_date,
      profileImg: info.profile_img,
      userName: info.user_name,
      fullName: info.full_name,
      biography: info.biography,
      post: info.post,
      follower: info.follower,
      follow: info.follow,
    };
    follow.forEach((e: { update_date: string; cnt: number }) => {
      followValues.push(e.cnt);
      followDates.push(e.update_date.substring(0, 10));
    });
    post.forEach((e: { update_date: string; cnt: number }) => {
      postValues.push(e.cnt);
      postDates.push(e.update_date.substring(0, 10));
    });
    follower.forEach((e: { update_date: string; cnt: number }) => {
      followerValues.push(e.cnt);
      followerDates.push(e.update_date.substring(0, 10));
    });
    like_average.forEach((e: { update_date: string; cnt: number }) => {
      like_averageValues.push(e.cnt);
      like_averageDates.push(e.update_date.substring(0, 10));
    });
    const chartData = {
      follow: {
        values: followValues,
        dates: followDates,
      },
      follower: {
        values: followerValues,
        dates: followerDates,
      },
      post: {
        values: postValues,
        dates: postDates,
      },
      like_average: {
        values: like_averageValues,
        dates: like_averageDates,
      },
    };
    const hashtags = exposed_hashtag.map(
      (e: { idx: number; hashtag: string; url: string }) => ({
        hashtag: e.hashtag,
        url: e.url,
      })
    );
    const suitability = onConvertPopularPostingExposureSuitability({
      postLevel: suitable_info.post_level,
      followerLevel: suitable_info.follower_level,
      activityLevel: suitable_info.activity_level,
      rankLevel: suitable_info.rank_level,
      rankWord: suitable_info.rank_word,
      workType: suitable_info.work_type,
    });
    return (
      <div
        style={{
          maxWidth: '1460px',
          minWidth: '1460px',
        }}
      >
        <FlexContainer
          style={{
            justifyContent: 'space-between',
            margin: '15px 0',
          }}
        >
          <div
            style={{
              padding: '6px 10px',
              backgroundColor: '#F2F2F2',
              fontSize: '14px',
            }}
          >
            {/* 데이터 분석일 */}
            {t('translation.DATA_ANALYSIS_DATE')} :{' '}
            {accountBasicInfo.updateDate}
          </div>
          <div
            style={{
              padding: '6px 10px',
              backgroundColor: '#F2F2F2',
              fontSize: '14px',
            }}
          >
            {/* 검색 키워드 */}
            {t('translation.TABLE_SEARCH_KEYWORD')} :{' '}
            <span style={{ color: '#4A5BFD' }}>
              {accountBasicInfo.userName}
            </span>
          </div>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer width={480}>
            <SmallPublicBoxWithIcon
              key={nanoid()}
              iconName={dateInfo.iconName}
              width={dateInfo.width}
              height={dateInfo.height}
              value={accountBasicInfo.updateDate.substring(0, 10)}
              label={t('translation.INQUIRY_DATE')}
            />
            <Gap height={40} />
            <PublicBox width={480} height={330}>
              <FlexContainer
                style={{
                  margin: '15px 15px 0 15px',
                }}
                width={410}
              >
                <img
                  style={{ width: '110px', height: '110px', marginTop: '11px' }}
                  src={accountBasicInfo.profileImg}
                  alt="profile_img"
                />
                <FlexContainer width={280} style={{ flexDirection: 'column' }}>
                  <PublicText
                    style={{
                      marginBottom: '10px',
                      width: '100%',
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                    value={accountBasicInfo.userName}
                  />
                  <PublicText
                    style={{ marginBottom: '15px', fontSize: '18px' }}
                    value={accountBasicInfo.fullName}
                  />
                  <PublicText
                    style={{
                      fontSize: '14px',
                      color: '#555555',
                      lineHeight: '20px',
                      height: '60px',
                      textOverflow: 'ellipsis',
                    }}
                    value={accountBasicInfo.biography}
                  />
                </FlexContainer>
              </FlexContainer>
              <div
                style={{
                  position: 'absolute',
                  left: '0px',
                  bottom: '40px',
                  display: 'flex',
                  height: '80px',
                }}
              >
                <FlexContainer
                  style={{
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    width: '160px',
                    height: '80px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <PublicText
                    style={{
                      fontFamily: 'SCDream',
                      fontSize: '38px',
                      color: '#4A5BFD',
                    }}
                    value={convertNumberNotation(accountBasicInfo.post)}
                  />
                  <PublicText
                    style={{ fontSize: '18px', color: '#555555' }}
                    // value="포스트"
                    value={t('translation.TABLE_POST')}
                  />
                </FlexContainer>
                <div
                  style={{
                    marginTop: '20px',
                    width: '2px',
                    height: '40px',
                    backgroundColor: '#CECECE',
                  }}
                />
                <FlexContainer
                  style={{
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    width: '160px',
                    height: '80px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <PublicText
                    style={{
                      fontFamily: 'SCDream',
                      fontSize: '38px',
                      color: '#2D7AFF',
                    }}
                    value={convertNumberNotation(accountBasicInfo.follower)}
                  />
                  <PublicText
                    style={{ fontSize: '18px', color: '#555555' }}
                    // value="팔로워"
                    value={t('translation.TABLE_FOLLOWER')}
                  />
                </FlexContainer>
                <div
                  style={{
                    marginTop: '20px',
                    width: '2px',
                    height: '40px',
                    backgroundColor: '#CECECE',
                  }}
                />
                <FlexContainer
                  style={{
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    width: '160px',
                    height: '80px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <PublicText
                    style={{
                      fontFamily: 'SCDream',
                      fontSize: '38px',
                      color: '#9068FF',
                    }}
                    value={convertNumberNotation(accountBasicInfo.follow)}
                  />
                  <PublicText
                    style={{ fontSize: '18px', color: '#555555' }}
                    // value="팔로우"
                    value={t('translation.TABLE_FOLLOW')}
                  />
                </FlexContainer>
              </div>
            </PublicBox>
          </FlexContainer>
          <Gap width={40} />
          <FlexContainer width={940}>
            <PublicBoxWithTitle title="팔로잉 수" width={450} height={220}>
              {chartData.follow.values.length === 0 ? (
                <NoDataCase top={90} />
              ) : (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      top: '26px',
                      right: '18px',
                      color: '#A1A1A1',
                    }}
                  >
                    {`${chartData.follow.dates[0]} ~ ${
                      chartData.follow.dates[chartData.follow.dates.length - 1]
                    }`}
                  </div>
                  <AreaChart
                    tooltip={t('translation.FOLLOWER_COUNT')}
                    dates={chartData.follow.dates}
                    values={chartData?.follow.values}
                  />
                </>
              )}
            </PublicBoxWithTitle>
            <PublicBoxWithTitle
              title={t('translation.POST_COUNT')}
              width={450}
              height={220}
            >
              {chartData.post.values.length === 0 ? (
                <NoDataCase top={90} />
              ) : (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      top: '26px',
                      right: '18px',
                      color: '#A1A1A1',
                    }}
                  >
                    {`${chartData.post.dates[0]} ~ ${
                      chartData.post.dates[chartData.post.dates.length - 1]
                    }`}
                  </div>

                  <AreaChart
                    tooltip={t('translation.POST_COUNT')}
                    dates={chartData.post.dates}
                    values={chartData?.post.values}
                  />
                </>
              )}
            </PublicBoxWithTitle>
            <Gap height={40} />
            <PublicBoxWithTitle
              title={t('translation.FOLLOWER_COUNT')}
              width={450}
              height={220}
            >
              {chartData.follower.values.length === 0 ? (
                <NoDataCase top={90} />
              ) : (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      top: '26px',
                      right: '18px',
                      color: '#A1A1A1',
                    }}
                  >
                    {`${chartData.follower.dates[0]} ~ ${
                      chartData.follower.dates[
                        chartData.follower.dates.length - 1
                      ]
                    }`}
                  </div>
                  <AreaChart
                    tooltip={t('translation.FOLLOWER_COUNT')}
                    dates={chartData.follower.dates}
                    values={chartData?.follower.values}
                  />
                </>
              )}
            </PublicBoxWithTitle>
            <PublicBoxWithTitle
              title={t('translation.LIKE_COUNT_AVERAGE')}
              width={450}
              height={220}
            >
              {chartData.like_average.values.length === 0 ? (
                <NoDataCase top={90} />
              ) : (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      top: '26px',
                      right: '18px',
                      color: '#A1A1A1',
                    }}
                  >
                    {`${chartData.like_average.dates[0]} ~ ${
                      chartData.like_average.dates[
                        chartData.like_average.dates.length - 1
                      ]
                    }`}
                  </div>
                  <AreaChart
                    tooltip={t('translation.LIKE_COUNT_AVERAGE')}
                    dates={chartData.like_average.dates}
                    values={chartData?.like_average.values}
                  />
                </>
              )}
            </PublicBoxWithTitle>
          </FlexContainer>
        </FlexContainer>
        <Gap height={40} />
        <FlexContainer>
          <PublicBoxWithTitle
            title={t('translation.POPULAR_POSTING_EXPOSURE_HASHTAG')}
            width={480}
            height={456}
          >
            {hashtags.length === 0 ? (
              <NoDataCase top={200} />
            ) : (
              <ExposedHashtags tags={hashtags} />
            )}
          </PublicBoxWithTitle>
          <PublicBoxWithTitle
            title={t('translation.POPULAR_POSTING_EXPOSURE_SUITABILITY')}
            width={940}
            height={456}
          >
            <AccountSuitability
              evaluation={suitability.rankWord}
              guide={suitability.workType}
            />
            <DetailSuitability
              post={suitability.postLevel}
              follower={suitability.followerLevel}
              activity={suitability.activityLevel}
            />
          </PublicBoxWithTitle>
        </FlexContainer>
      </div>
    );
  }
  if (orderedItem.serviceCode === 100) {
    const { update_date, hashtag } = orderedItem;
    const chargedItem = {
      ...chargedItemTableFrame,
      data: orderedItem.data,
      updateDate: orderedItem.update_date,
    };
    return (
      <div
        style={{
          maxWidth: '710px',
          minWidth: '710px',
        }}
      >
        <FlexContainer
          style={{
            justifyContent: 'space-between',
            margin: '15px 0',
          }}
        >
          <div
            style={{
              padding: '6px 10px',
              backgroundColor: '#F2F2F2',
              fontSize: '14px',
            }}
          >
            {t('translation.DATA_ANALYSIS_DATE')} :{update_date}
          </div>
          <div
            style={{
              padding: '6px 10px',
              backgroundColor: '#F2F2F2',
              fontSize: '14px',
            }}
          >
            {t('translation.TABLE_SEARCH_KEYWORD')} :{' '}
            <span style={{ color: '#4A5BFD' }}>
              {hashtag || chargedItem.data[0].hashtag}
            </span>
          </div>
        </FlexContainer>
        <PublicBoxWithTitle
          title={t('translation.INCLUDE_WORD_HASHTAG')}
          width={710}
          height={800}
        >
          <BasicTable tableData={chargedItem} tbodyHeight={670} />
        </PublicBoxWithTitle>
      </div>
    );
  }
  if (orderedItem.serviceCode === 101) {
    const {
      update_date,
      hashtag,
      post,
      top_id_7,
      top_id_30,
      top_id_90,
      top_id_180,
      top_like,
      top_comment,
    } = orderedItem;
    const exposedAccountBasicInfo = [
      {
        iconName: SMALLBOXICON.exposedaccount_date,
        label: t('translation.INQUIRY_DATE'),
        width: 460,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.exposedaccount_hashtag,
        label: t('translation.HASHTAG'),
        width: 460,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.exposedaccount_post,
        label: t('translation.POSTING_COUNT'),
        width: 460,
        height: 110,
      },
    ].map((info, i) => {
      if (i === 0) return { ...info, value: update_date };
      if (i === 1) return { ...info, value: hashtag };
      return { ...info, value: post };
    });
    const tableData = {
      top_id_7: {
        tableCode: CODE.ESV,
        // title: '최근 7일 인기게시물 계정 순위',
        title: t('translation.RECENT_WEEK_POPULAR_POSTING_ACCOUNT_RANKING'),
        columns: [
          'NO',
          t('translation.TABLE_SCORE'), // '점수',
          t('translation.TABLE_ACCOUNT'), // '계정',
          t('translation.TABLE_FOLLOWER'), // '팔로워',
          t('translation.TABLE_FOLLOW'), // '팔로우',
          t('translation.TABLE_POST'), // '포스트',
          t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요',
        ],
        data: top_id_7,
      },
      top_id_30: {
        tableCode: CODE.ESV,
        // title: '최근 30일 인기게시물 계정 순위',
        title: t('translation.RECENT_MONTH_POPULAR_POSTING_ACCOUNT_RANKING'),
        columns: [
          'NO',
          t('translation.TABLE_SCORE'), // '점수',
          t('translation.TABLE_ACCOUNT'), // '계정',
          t('translation.TABLE_FOLLOWER'), // '팔로워',
          t('translation.TABLE_FOLLOW'), // '팔로우',
          t('translation.TABLE_POST'), // '포스트',
          t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요',
        ],
        data: top_id_30,
      },
      top_id_90: {
        tableCode: CODE.ESV,
        // title: '최근 90일 인기게시물 계정 순위',
        title: t('translation.RECENT_QUARTER_POPULAR_POSTING_ACCOUNT_RANKING'),
        columns: [
          'NO',
          t('translation.TABLE_SCORE'), // '점수',
          t('translation.TABLE_ACCOUNT'), // '계정',
          t('translation.TABLE_FOLLOWER'), // '팔로워',
          t('translation.TABLE_FOLLOW'), // '팔로우',
          t('translation.TABLE_POST'), // '포스트',
          t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요',
        ],
        data: top_id_90,
      },
      top_id_180: {
        tableCode: CODE.ESV,
        // title: '최근 180일 인기게시물 계정 순위',
        title: t(
          'translation.RECENT_HALF_YEAR_POPULAR_POSTING_ACCOUNT_RANKING'
        ),
        columns: [
          'NO',
          t('translation.TABLE_SCORE'), // '점수',
          t('translation.TABLE_ACCOUNT'), // '계정',
          t('translation.TABLE_FOLLOWER'), // '팔로워',
          t('translation.TABLE_FOLLOW'), // '팔로우',
          t('translation.TABLE_POST'), // '포스트',
          t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요',
        ],
        data: top_id_180,
      },
      top_like: {
        tableCode: CODE.EN,
        // title: '해시태그 TOP 좋아요 게시물',
        title: t('translation.HASHTAG_TOP_LIKE_POSTING'),
        columns: [
          'NO',
          t('translation.TABLE_POSTING_DATE'), // '포스팅 날짜',
          t('translation.TABLE_LIKE'), // '좋아요',
          t('translation.TABLE_COMMENT'), // '댓글',
          t('translation.TABLE_ACCOUNT'), // '계정',
          t('translation.TABLE_FOLLOWER'), // '팔로워',
          t('translation.TABLE_FOLLOW'), // '팔로우',
          t('translation.TABLE_POST'), // '포스트',
          t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요',
        ],
        data: top_like,
      },
      top_comment: {
        tableCode: CODE.EN,
        // title: '해시태그 TOP 댓글 게시물',
        title: t('translation.HASHTAG_TOP_COMMENT_POSTING'),
        columns: [
          'NO',
          t('translation.TABLE_POSTING_DATE'), // '포스팅 날짜',
          t('translation.TABLE_LIKE'), // '좋아요',
          t('translation.TABLE_COMMENT'), // '댓글',
          t('translation.TABLE_ACCOUNT'), // '계정',
          t('translation.TABLE_FOLLOWER'), // '팔로워',
          t('translation.TABLE_FOLLOW'), // '팔로우',
          t('translation.TABLE_POST'), // '포스트',
          t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요',
        ],
        data: top_comment,
      },
    };

    return (
      <div
        style={{
          maxWidth: '1460px',
          minWidth: '1460px',
        }}
      >
        <FlexContainer
          style={{
            justifyContent: 'space-between',
            margin: '15px 0',
          }}
        >
          <div
            style={{
              padding: '6px 10px',
              backgroundColor: '#F2F2F2',
              fontSize: '14px',
            }}
          >
            {/* 데이터 분석일 :{update_date} */}
            {t('translation.DATA_ANALYSIS_DATE')} :{update_date}
          </div>
          <div
            style={{
              padding: '6px 10px',
              backgroundColor: '#F2F2F2',
              fontSize: '14px',
            }}
          >
            {t('translation.SEARCH_KEYWORD')} :{' '}
            <span style={{ color: '#4A5BFD' }}>{hashtag}</span>
          </div>
        </FlexContainer>
        <FlexContainer>
          {exposedAccountBasicInfo?.map((info, i) => (
            <SmallPublicBoxWithIcon
              key={nanoid()}
              iconName={info.iconName}
              width={info.width}
              height={info.height}
              value={
                typeof info.value === 'number'
                  ? addCommaToNumber(info.value.toString())
                  : i === 0
                  ? info.value?.substring(0, 10)
                  : info.value
              }
              label={info.label}
            />
          ))}
        </FlexContainer>
        <Gap height={40} />
        <FlexContainer>
          <PublicBoxWithTitle
            title={tableData?.top_id_7.title}
            width={710}
            height={504}
          >
            <BasicTable tableData={tableData.top_id_7} tbodyHeight={380} />
          </PublicBoxWithTitle>
          <PublicBoxWithTitle
            title={tableData?.top_id_30.title}
            width={710}
            height={504}
          >
            <BasicTable tableData={tableData.top_id_30} tbodyHeight={380} />
          </PublicBoxWithTitle>
          <Gap height={40} />
          <PublicBoxWithTitle
            title={tableData?.top_id_90.title}
            width={710}
            height={504}
          >
            <BasicTable tableData={tableData.top_id_90} tbodyHeight={380} />
          </PublicBoxWithTitle>
          <PublicBoxWithTitle
            title={tableData?.top_id_180.title}
            width={710}
            height={504}
          >
            <BasicTable tableData={tableData.top_id_180} tbodyHeight={380} />
          </PublicBoxWithTitle>
          <Gap height={40} />
          <PublicBoxWithTitle
            title={tableData?.top_like.title}
            width={1460}
            height={504}
          >
            <BasicTable tableData={tableData.top_like} tbodyHeight={380} />
          </PublicBoxWithTitle>
          <Gap height={40} />
          <PublicBoxWithTitle
            title={tableData?.top_comment.title}
            width={1460}
            height={504}
          >
            <BasicTable tableData={tableData.top_comment} tbodyHeight={380} />
          </PublicBoxWithTitle>
        </FlexContainer>
      </div>
    );
  }
  if (orderedItem.serviceCode === 102) {
    const daysByAmountValues: number[] = [];
    const daysByAmountDates: string[] = [];
    const monthsByAmountValues: number[] = [];
    const monthsByAmountDates: string[] = [];

    const {
      update_date,
      hashtag,
      post,
      monthly_increase_average,
      days_by_amount,
      month_by_amount,
      post_log,
    } = orderedItem;
    const postIncreaseBasicInfo = [
      {
        iconName: SMALLBOXICON.account_post,
        // label: '조회 날짜',
        label: t('translation.INQUIRY_DATE'),
        width: 335,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.exposedaccount_hashtag,
        // label: '해시태그',
        label: t('translation.HASHTAG'),
        width: 335,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.exposedaccount_post,
        // label: '게시물 수',
        label: t('translation.POSTING_COUNT'),
        width: 335,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.postincrease_monthlyincrease,
        // label: '월간 평균 게시물 증가수',
        label: t('translation.MONTHLY_AVERAGE_POSTING_INCREASE_COUNT'),
        width: 335,
        height: 110,
      },
    ].map((info, i) => {
      if (i === 0) return { ...info, value: update_date.substring(0, 10) };
      if (i === 1) return { ...info, value: hashtag };
      if (i === 2) return { ...info, value: post };
      return { ...info, value: monthly_increase_average };
    });

    days_by_amount.forEach(
      (e: { checked_day: string; daily_increase_amount: number }) => {
        let day = '';

        if (e.checked_day === '일') {
          day = t('translation.SUNDAY');
        }

        if (e.checked_day === '월') {
          day = t('translation.MONDAY');
        }

        if (e.checked_day === '화') {
          day = t('translation.TUESDAY');
        }

        if (e.checked_day === '수') {
          day = t('translation.WEDNESDAY');
        }

        if (e.checked_day === '목') {
          day = t('translation.THURSDAY');
        }

        if (e.checked_day === '금') {
          day = t('translation.FRIDAY');
        }

        if (e.checked_day === '토') {
          day = t('translation.SATURDAY');
        }

        daysByAmountValues.push(e.daily_increase_amount);
        daysByAmountDates.push(day);
      }
    );
    month_by_amount.forEach(
      (e: { month: string; monthly_increase_amount: number }) => {
        let month = '';

        if (e.month === '1월') {
          month = t('translation.JANUARY');
        }
        if (e.month === '2월') {
          month = t('translation.FEBRUARY');
        }
        if (e.month === '3월') {
          month = t('translation.MARCH');
        }
        if (e.month === '4월') {
          month = t('translation.APRIL');
        }
        if (e.month === '5월') {
          month = t('translation.MAY');
        }
        if (e.month === '6월') {
          month = t('translation.JUNE');
        }
        if (e.month === '7월') {
          month = t('translation.JULY');
        }
        if (e.month === '8월') {
          month = t('translation.AUGUST');
        }
        if (e.month === '9월') {
          month = t('translation.SEPTEMBER');
        }
        if (e.month === '10월') {
          month = t('translation.OCTOBER');
        }
        if (e.month === '11월') {
          month = t('translation.NOVEMBER');
        }
        if (e.month === '12월') {
          month = t('translation.DECEMBER');
        }

        monthsByAmountValues.push(e.monthly_increase_amount);
        monthsByAmountDates.push(month);
      }
    );
    const postLog = post_log.map(
      (e: { check_date: string; post_cnt: number }) => ({
        value: e.post_cnt,
        date: e.check_date,
      })
    );
    const chartData = {
      daysByAmount: {
        values: daysByAmountValues,
        dates: daysByAmountDates,
      },
      monthsByAmount: {
        values: monthsByAmountValues,
        dates: monthsByAmountDates,
      },
      log: postLog,
    };

    return (
      <div
        style={{
          maxWidth: '1460px',
          minWidth: '1460px',
        }}
      >
        <FlexContainer
          style={{
            justifyContent: 'space-between',
            margin: '15px 0',
          }}
        >
          <div
            style={{
              padding: '6px 10px',
              backgroundColor: '#F2F2F2',
              fontSize: '14px',
            }}
          >
            {t('translation.DATA_ANALYSIS_DATE')} :{update_date}
          </div>
          <div
            style={{
              padding: '6px 10px',
              backgroundColor: '#F2F2F2',
              fontSize: '14px',
            }}
          >
            {t('translation.SEARCH_KEYWORD')} :{' '}
            <span style={{ color: '#4A5BFD' }}>{hashtag}</span>
          </div>
        </FlexContainer>
        <FlexContainer>
          {postIncreaseBasicInfo?.map((info, i) => (
            <SmallPublicBoxWithIcon
              key={nanoid()}
              iconName={info.iconName}
              width={info.width}
              height={info.height}
              value={
                i === 2 || i === 3 ? addCommaToNumber(info.value) : info.value
              }
              label={info.label}
            />
          ))}
        </FlexContainer>
        <Gap height={40} />
        <FlexContainer>
          <PublicBoxWithTitle
            // title="요일별 게시물 증가량"
            title={t('translation.DAYS_POSTING_INCREASE_COUNT')}
            width={532}
            height={512}
          >
            <BarChart
              dates={chartData.daysByAmount.dates}
              values={chartData.daysByAmount.values}
            />
          </PublicBoxWithTitle>
          <PublicBoxWithTitle
            // title="월별 게시물 증가량"
            title={t('translation.MONTHLY_POSTING_INCREASE_COUNT')}
            width={888}
            height={512}
          >
            <BarChart
              dates={chartData.monthsByAmount.dates}
              values={chartData.monthsByAmount.values}
            />
          </PublicBoxWithTitle>
        </FlexContainer>
        <Gap height={40} />
        <PublicBoxWithTitle
          title={t('translation.POST_INCREASE_TRANSITION')}
          width={1460}
          height={568}
        >
          <RechartLineChart data={chartData.log} />
        </PublicBoxWithTitle>
      </div>
    );
  }

  return <>fallback</>;
};
