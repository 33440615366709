import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootReducer from './rootReducer';
import {
  authApi,
  usersApi,
  serviceApi,
  lookupApi,
  reportApi,
  noticeApi,
  faqApi,
} from './api';

const apiMiddlewares = [
  authApi.middleware,
  usersApi.middleware,
  serviceApi.middleware,
  lookupApi.middleware,
  reportApi.middleware,
  noticeApi.middleware,
  faqApi.middleware,
];

export const store = configureStore({
  reducer: {
    rootReducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [lookupApi.reducerPath]: lookupApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [noticeApi.reducerPath]: noticeApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiMiddlewares),
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
