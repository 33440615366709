import { ChangeEvent, useEffect, useRef } from 'react';
import type { FormEvent } from 'react';
import LogoPath from '@assets/images/logo/snstrend_logo_2.png';
import { PublicButton } from '@atoms/buttons';
import { PublicText } from '@atoms/texts';
import * as S from './LoginForm.style';

const Logo = () => {
  return (
    <S.Logo>
      <img src={LogoPath} alt="logo" style={{ width: '100%' }} />
    </S.Logo>
  );
};

interface ButtonsProps {
  onConnectToKakaotalk: (e: any) => void;
}
const Buttons = ({ onConnectToKakaotalk }: ButtonsProps) => {
  return (
    <S.Buttons>
      <S.Button type="button" onClick={onConnectToKakaotalk}>
        <PublicText
          value="회원가입"
          style={{
            fontSize: '16px',
          }}
        />
      </S.Button>
      <S.Button type="button" onClick={onConnectToKakaotalk}>
        <PublicText
          value="아이디/비밀번호 찾기"
          style={{
            fontSize: '16px',
          }}
        />
      </S.Button>
    </S.Buttons>
  );
};

interface HelpButtonProps {
  onConnectToKakaotalk: (e: any) => void;
}
const HelpButton = ({ onConnectToKakaotalk }: HelpButtonProps) => {
  return (
    <S.HelpButton>
      <PublicText
        value="도움이 필요하신가요?"
        style={{
          fontSize: '18px',
        }}
      />
      <S.Button type="button" onClick={onConnectToKakaotalk}>
        <PublicText
          value="고객센터 바로가기"
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#4A5BFD',
          }}
        />
      </S.Button>
    </S.HelpButton>
  );
};

interface Props {
  isError: boolean;
  onSubmit: (event: FormEvent<Element>) => void;
  onInputChange: (
    name: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  onConnectToKakaotalk: (e: any) => void;
}

export const LoginForm = ({
  isError,
  onSubmit,
  onInputChange,
  onConnectToKakaotalk,
}: Props) => {
  const inputRef = useRef<any>('');
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <S.LoginForm onSubmit={onSubmit}>
      <Logo />
      <S.Inputs>
        <S.PublicInput
          ref={inputRef}
          type="text"
          style={{
            padding: '18px 20px',
            width: '350px',
            height: '64px',
            fontSize: '18px',
          }}
          placeholder="아이디"
          onChange={onInputChange('id')}
        />
        <S.PublicInput
          type="password"
          autoComplete="off"
          style={{
            padding: '18px 20px',
            width: '350px',
            height: '64px',
            fontSize: '18px',
          }}
          placeholder="비밀번호"
          onChange={onInputChange('pw')}
        />
        {isError && (
          <PublicText
            value="아이디 또는 비밀번호를 확인해 주세요."
            style={{
              position: 'absolute',
              bottom: '-22px',
              fontSize: '14px',
              color: '#FF1C1C',
            }}
          />
        )}
      </S.Inputs>
      <Buttons onConnectToKakaotalk={onConnectToKakaotalk} />
      <PublicButton
        type="submit"
        style={{
          width: '350px',
          height: '70px',
          fontSize: '20px',
        }}
        value="로그인"
      />
      <HelpButton onConnectToKakaotalk={onConnectToKakaotalk} />
    </S.LoginForm>
  );
};
