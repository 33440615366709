import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { store } from '@features/rootStore';
import favicon from '@assets/images/favicon-16x16.png';
import Root from './routes/Root';
import GlobalStyle from './styles/global';
import { theme } from './styles/theme';
import './styles/fonts.css';
import '@language/i18n';

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <title>SNSTrend</title>
      <link rel="icon" href={favicon} />
    </Helmet>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={null}>
        <Router>
          <React.StrictMode>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Root />
            </ThemeProvider>
          </React.StrictMode>
        </Router>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
