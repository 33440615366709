import { useEffect } from 'react';
import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import { nanoid } from 'nanoid';
import { TABLE_PAGINATION } from '@constants';

const StyledTr = styled.tr`
  background-color: #fff;
  &:hover {
    background-color: #f9fbff;
  }
`;

interface ChargeTable {
  no: string;
  method: string;
  type: string;
  point: string;
  date: string;
}
interface Props {
  columns: any;
  data: any;
}

export const Table = ({ columns, data }: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable<ChargeTable>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
    },
    usePagination
  );
  useEffect(() => {
    setPageSize(15);
  }, []);

  const addedPage = Math.floor(pageIndex / 10) * 10;
  const shownPages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    .map(pageNum => pageNum + addedPage)
    .filter(pageNum => pageNum <= pageCount);

  return (
    <>
      <table
        {...getTableProps()}
        style={{
          position: 'relative',
          margin: '0 auto',
          width: '1420px',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={nanoid()}
              style={{
                height: '40px',
              }}
            >
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps()}
                  key={nanoid()}
                  style={{
                    width: i === 0 ? '124px' : '314px',
                    background: '#F2F2F2',
                    color: '#555555',
                    fontWeight: 'bold',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <StyledTr
                {...row.getRowProps()}
                key={nanoid()}
                style={{
                  height: '40px',
                  borderBottom: '1px dashed #E8E8E8',
                }}
              >
                {row.cells.map((cell: any, i: number) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={nanoid()}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {cell.render('Cell')}
                      {i === 3 ? 'P' : ''}
                    </td>
                  );
                })}
              </StyledTr>
            );
          })}
        </tbody>
      </table>
      <div
        className="pagination"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <button
          type="button"
          style={{
            marginRight: '6px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => gotoPage(0)}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.gotofirst}
            alt="go to first"
          />
        </button>{' '}
        <button
          type="button"
          style={{
            marginRight: '20px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => previousPage()}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.prev}
            alt="prev"
          />
        </button>
        {shownPages.map((pageNum, i) => {
          return (
            <button
              type="button"
              key={nanoid()}
              style={{
                marginRight: i === shownPages.length - 1 ? 0 : '2px',
                width: '24px',
                height: '24px',
                border: 0,
                borderRadius: '5px',
                background: pageNum === pageIndex + 1 ? '#555555' : 0,
                fontSize: '14px',
                color: pageNum === pageIndex + 1 ? '#fff' : '#000',
                cursor: 'pointer',
              }}
              onClick={() => gotoPage(pageNum - 1)}
            >
              {pageNum}
            </button>
          );
        })}
        <button
          type="button"
          style={{
            marginLeft: '20px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => nextPage()}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.next}
            alt="next"
          />
        </button>{' '}
        <button
          type="button"
          style={{
            marginLeft: '6px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => gotoPage(pageCount - 1)}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.gotolast}
            alt="go to last"
          />
        </button>{' '}
      </div>
    </>
  );
};
