import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

export function addCommaToNumber(value: any): string {
  const result = value.toString();
  return result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function convertNumberNotation(value: string | number) {
  let result = 0;
  if (typeof value === 'string') {
    result = Number(value);
  }
  if (typeof value === 'number') {
    result = value;
  }

  if (result >= 100000 && result < 1000000) {
    return `${Math.floor(result / 1000)}K`;
  }
  if (result >= 1000000) {
    return `${Math.floor(result / 1000000)}M`;
  }
  return result.toString();
}
export function openNewTab(url: string): void {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
}

export const isFetchBaseQueryErrorType = (
  error: any
): error is FetchBaseQueryError => 'status' in error;
