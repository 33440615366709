import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import { PublicBox } from '@atoms/boxes';
import { PublicSearchInput } from '@atoms/inputs';
import { PublicText } from '@atoms/texts';
import { Gap, Modal, Spinner, ReportSpinner } from '@atoms/utils';
import { BasicTable, TableOptions } from '@atoms/tables';
import { PublicBoxWithTitle } from '@molecules/boxes';
import MainTemplate from '@templates/MainTemplate';
import {
  tableCodes as CODE,
  MAININITTEMPALTE_LOGOS as LOGO,
  STARTAG_DESCRIPTION as DESCRIPTION,
} from '@constants';
import {
  useServiceItemCheckApi,
  useServiceApi,
  useModal,
  useMounted,
  useReport,
} from '@hooks';
import { addCommaToNumber } from '@utils';
import { Block } from './Block';
import * as S from './Startag.style';

// const freeItemTableFrame: TableSet = {
//   tableCode: CODE.SFV,
//   title: '추천 자동완성, 연관해시태그',
//   columns: ['NO', '*점수', '수집기준', '수집 해시태그', '게시물 수'],
// };
// const chargedItemTableFrame: TableSet = {
//   tableCode: CODE.SF,
//   title: '단어포함 해시태그',
//   columns: ['NO', '수집기준', '수집 해시태그', '게시물 수'],
// };

const Startag: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  const {
    isShown,
    modalContent,
    onModalOpen,
    onModalClose,
    openServiceErrorModal,
  } = useModal();
  const {
    exportStartagFree,
    exportStartagCharged,
    errorCode: reportErrorCode,
    setErrorCode: setReportErrorCode,
  } = useReport();
  const { checkKeywordIsOrdered, checkIsReportDownloaded } =
    useServiceItemCheckApi();
  const {
    getStartagFree,
    getStartagFreeItemState,
    getNew,
    getNewChargedItemState,
    getExist,
    getExistChargedItemState,
  } = useServiceApi();
  const { isMounted } = useMounted();

  const [inputHashtag, setInputHashtag] = useState<string>('');
  const [orderedInfo, setOrderedInfo] = useState<string>('');
  const [isTableOptionsGetNewDisabled, setIsTableOptionsGetNewDisabled] =
    useState<boolean>(false);
  const [purchasedItemStatus, setPurchasedItemStatus] = useState(0);
  const [isBlockGetNewDisabled, setIsBlockGetNewDisabled] =
    useState<boolean>(false);
  const [isBlockGetExistDisabled, setIsBlockGetExistDisabled] =
    useState<boolean>(false);
  const [
    isTableOptionsChargedReportSaveDisabled,
    setIsTableOptionsChargedReportSaveDisabled,
  ] = useState<boolean>(true);
  const [freeItem, setFreeItem] = useState<TableSet | null>(null);
  const [chargedItem, setChargedItem] = useState<TableSet | null>(null);
  const [freeReportNo, setFreeReportNo] = useState<number>(0);
  const [chargedReportNo, setChargedReportNo] = useState<number>(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [itemCnt, setItemCnt] = useState(0);

  const searchedHashtagRef = useRef<string>('');

  useEffect(() => {
    if (searchedHashtagRef.current === '') setInputHashtag('');
  }, [isShown]);
  useEffect(() => {
    if (isMounted && reportErrorCode !== '') {
      openServiceErrorModal(reportErrorCode);
    }
    setReportErrorCode('');
  }, [reportErrorCode]);

  const handleSearchButtonClick = async () => {
    if (inputHashtag === '') return;
    try {
      if (
        inputHashtag.includes('\\') ||
        inputHashtag.includes('/') ||
        inputHashtag.includes('?') ||
        inputHashtag.includes('!') ||
        inputHashtag.includes('@') ||
        inputHashtag.includes('#')
      ) {
        throw Error;
      }
      const { status, order_info } = await checkKeywordIsOrdered(
        'startag',
        inputHashtag
      );
      const responseData = await getStartagFree(inputHashtag);
      if (responseData) {
        if (responseData.status >= 400) {
          openServiceErrorModal(responseData.data.code);
        } else {
          setItemCnt(responseData.include_cnt);
          setItemPrice(responseData.include_price);
          setIsTableOptionsGetNewDisabled(false);
          setIsTableOptionsChargedReportSaveDisabled(true);
          setChargedItem(null);
          searchedHashtagRef.current = inputHashtag;
          if (order_info !== '0') setOrderedInfo(order_info);
          setFreeItem({
            tableCode: CODE.SFV,
            title: `${t('translation.RECOMMEND_AUTO_COMPLETE')}, ${t(
              'translation.RELATED_HASHTAG'
            )}`,
            columns: [
              'NO',
              `*${t('translation.TABLE_SCORE')}`,
              t('translation.TABLE_COLLECTION_STANDARD'),
              t('translation.TABLE_COLLECTION_HASHTAG'),
              t('translation.TABLE_POSTING_COUNT'),
            ],
            data: responseData.data,
            updateDate: responseData.update_date,
          });
          setFreeReportNo(responseData.no);
          setIsBlockGetNewDisabled(status === -1);
          setIsTableOptionsGetNewDisabled(status === -1);
          setIsBlockGetExistDisabled(status === 0);
          setPurchasedItemStatus(status);
        }
      }
    } catch (e) {
      openServiceErrorModal();
    }
  };

  // 포함단어태그 테이블 위 신규데이터분석
  const handleGetNewChargedItemTableOptionsButtonClick = () => {
    if (isTableOptionsGetNewDisabled) return;
    onModalOpen({
      onFirstButtonClick: async () => {
        const responseData = await getNew(
          'star_include',
          searchedHashtagRef.current
        );
        if (responseData) {
          if (responseData.status >= 400) {
            openServiceErrorModal(responseData.data.code);
          } else {
            setIsTableOptionsGetNewDisabled(true);
            setIsTableOptionsChargedReportSaveDisabled(false);
            setChargedItem({
              tableCode: CODE.SF,
              title: t('translation.WORD_INCLUDE_HASHTAG'),
              columns: [
                'NO',
                t('translation.TABLE_COLLECTION_STANDARD'),
                t('translation.TABLE_COLLECTION_HASHTAG'),
                t('translation.TABLE_POSTING_COUNT'),
              ],
              data: responseData.data,
              updateDate: responseData.update_date,
            });
            setChargedReportNo(responseData.no);
          }
        }
      },
      message: t('translation.PREMIUM_SERVICE'),
      subMessage: [
        `${t('translation.PREMIUM_SERVICE_TEXT1')} ${addCommaToNumber(
          itemPrice
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ],
      firstButtonText: t('translation.NEW_DATA_ANALYSIS_BUTTON'),
    });
  };

  // 포함단어태그 신규분석 조회
  const handleGetNewChargedItemButtonClick = async () => {
    if (isBlockGetNewDisabled) return;
    const responseData = await getNew(
      'star_include',
      searchedHashtagRef.current
    );
    if (responseData) {
      if (responseData.status >= 400) {
        openServiceErrorModal(responseData.data.code);
      } else {
        setIsTableOptionsGetNewDisabled(true);
        setIsTableOptionsChargedReportSaveDisabled(false);
        setChargedItem({
          tableCode: CODE.SF,
          title: t('translation.WORD_INCLUDE_HASHTAG'),
          columns: [
            'NO',
            t('translation.TABLE_COLLECTION_STANDARD'),
            t('translation.TABLE_COLLECTION_HASHTAG'),
            t('translation.TABLE_POSTING_COUNT'),
          ],
          data: responseData.data,
          updateDate: responseData.update_date,
        });
        setChargedReportNo(responseData.no);
      }
    }
  };

  // 포함단어태그 기존분석 조회
  const handleGetExistChargedItemButtonClick = async () => {
    if (isBlockGetExistDisabled) return;
    const responseData = await getExist(orderedInfo);

    if (responseData) {
      if (responseData.status >= 400) {
        openServiceErrorModal(responseData.data.code);
      } else {
        setIsTableOptionsGetNewDisabled(purchasedItemStatus === -1);
        setIsTableOptionsChargedReportSaveDisabled(false);
        setChargedItem({
          tableCode: CODE.SF,
          title: t('translation.WORD_INCLUDE_HASHTAG'),
          columns: [
            'NO',
            t('translation.TABLE_COLLECTION_STANDARD'),
            t('translation.TABLE_COLLECTION_HASHTAG'),
            t('translation.TABLE_POSTING_COUNT'),
          ],
          data: responseData.data,
          updateDate: responseData.update_date,
        });
        setChargedReportNo(responseData.no);
      }
    }
  };

  // 추천자동완성, 연관해시태그 리포트 다운로드
  const handleFreeReportSave = async () => {
    const responseData = await checkIsReportDownloaded(
      'star_hashtag',
      freeReportNo
    );
    if (responseData) {
      const isReportDownload = true;
      const executedApi = (recipient = '') => {
        exportStartagFree(
          searchedHashtagRef.current,
          recipient,
          freeItem?.updateDate as string,
          freeItem?.data
        );
      };
      const message = t('translation.PREMIUM_SERVICE');
      const subMessage = [
        `${t('translation.PREMIUM_SERVICE_TEXT3')} ${addCommaToNumber(
          responseData.price
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ];
      const firstButtonText = t('translation.REPORT_SAVE_BUTTON');

      onModalOpen({
        onFirstButtonClick: executedApi,
        isReportDownload,
        message,
        subMessage,
        firstButtonText,
      });
    }
  };

  // 단어포함태그 리포트 다운로드
  const handleChargedReportSave = async () => {
    if (isTableOptionsChargedReportSaveDisabled) return;
    const responseData = await checkIsReportDownloaded(
      'startag',
      chargedReportNo
    );
    if (responseData) {
      const isReportDownload = true;
      const executedApi = (recipient = '') => {
        exportStartagCharged(
          searchedHashtagRef.current,
          recipient,
          chargedItem?.updateDate as string,
          chargedItem?.data
        );
      };
      const message = t('translation.PREMIUM_SERVICE');
      const subMessage = [
        `${t('translation.PREMIUM_SERVICE_TEXT3')} ${addCommaToNumber(
          responseData.price
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ];
      const firstButtonText = t('translation.REPORT_SAVE_BUTTON');
      onModalOpen({
        onFirstButtonClick: executedApi,
        isReportDownload,
        message,
        subMessage,
        firstButtonText,
      });
    }
  };

  const onConvertLanguage = () => {
    const originList = { ...DESCRIPTION, title: t('translation.STAR_TAG') };

    const res = originList.info.map((obj: any) => {
      let title = '';
      let content = '';

      if (obj.title === '스타태그란?') {
        title = t('translation.STAR_TAG_TITLE1');
        content = t('translation.STAR_TAG_TITLE1_DESCRIPTION1');
      }

      if (obj.title === '추천 사용자') {
        title = t('translation.STAR_TAG_TITLE2');
        content = t('translation.STAR_TAG_TITLE2_DESCRIPTION1');
      }

      if (obj.title === '분석항목') {
        title = t('translation.STAR_TAG_TITLE3');
        content = t('translation.STAR_TAG_TITLE3_DESCRIPTION1');
      }

      return {
        ...obj,
        title,
        content,
      };
    });

    return {
      ...originList,
      info: res,
    };
  };

  if (
    getStartagFreeItemState.isLoading ||
    getNewChargedItemState.isLoading ||
    getExistChargedItemState.isLoading
  ) {
    return <Spinner />;
  }
  return (
    <>
      {freeItem ? (
        <MainTemplate
          hasSearchInput
          onInputChange={e => setInputHashtag(e.target.value)}
          inputValue={inputHashtag}
          onSearchClick={handleSearchButtonClick}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSearchButtonClick();
            }
          }}
        >
          <S.MainContents>
            <S.Section>
              <TableOptions
                updateDate={freeItem.updateDate}
                onSaveReport={handleFreeReportSave}
              />
              <PublicBoxWithTitle
                title={`${t('translation.RECOMMEND_AUTO_COMPLETE')}, ${t(
                  'translation.RELATED_HASHTAG'
                )}`}
                width={710}
                height={800}
              >
                <BasicTable tableData={freeItem} tbodyHeight={670} />
              </PublicBoxWithTitle>
            </S.Section>
            <S.Section>
              <TableOptions
                isGetNewHave
                updateDate={chargedItem?.updateDate}
                onGetNewClick={handleGetNewChargedItemTableOptionsButtonClick}
                isGetNewDisabled={isTableOptionsGetNewDisabled}
                isTableOptionsChargedReportSaveDisabled={
                  isTableOptionsChargedReportSaveDisabled
                }
                onSaveReport={handleChargedReportSave}
              />

              <PublicBoxWithTitle
                title={t('translation.WORD_INCLUDE_HASHTAG')}
                width={710}
                height={800}
              >
                {chargedItem?.data ? (
                  <BasicTable tableData={chargedItem} tbodyHeight={670} />
                ) : (
                  <Block
                    subMessage={
                      purchasedItemStatus === -1
                        ? t(
                            'translation.ALREADY_NEW_ANALYSIS_RESULT_PURCHASE_TITLE'
                          )
                        : `${t(
                            'translation.ALREADY_NEW_ANALYSIS_RESULT_PURCHASE_TEXT'
                          )} ${addCommaToNumber(itemPrice)}${t(
                            'translation.PREMIUM_SERVICE_TEXT2'
                          )}`
                    }
                    itemCnt={itemCnt}
                    hashtag={searchedHashtagRef.current}
                    onGetNewButtonClick={handleGetNewChargedItemButtonClick}
                    onGetExistButtonClick={handleGetExistChargedItemButtonClick}
                    isBlockGetNewDisabled={isBlockGetNewDisabled}
                    isChargedItemExist={!isBlockGetExistDisabled}
                  />
                )}
              </PublicBoxWithTitle>
            </S.Section>
          </S.MainContents>
        </MainTemplate>
      ) : (
        <S.Container>
          <S.Contents>
            <img
              style={{ width: '76px', height: '76px' }}
              src={LOGO.startag}
              alt="logo"
            />
            <Gap height={28} />
            <PublicText
              style={{ fontSize: '40px', fontWeight: 'bold' }}
              value={t('translation.STAR_TAG')}
            />
            <Gap height={32} />
            <PublicSearchInput
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSearchButtonClick();
                }
              }}
              onSearchClick={handleSearchButtonClick}
              onInputChange={e => setInputHashtag(e.target.value)}
              placeholder={t('translation.INPUT_PLACEHOLDER_HASHTAG')}
              value={inputHashtag}
              style={{
                width: '748px',
                height: '70px',
                borderRadius: '10px',
                fontSize: '20px',
                inputWidth: '638px',
              }}
            />
            <Gap height={70} />
            <S.Infos>
              {onConvertLanguage().info.map((info, i) => {
                const texts: any[] = info.content.split('/');
                return (
                  <PublicBox
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '30px 10px',
                    }}
                    width={236}
                    height={250}
                    key={nanoid()}
                  >
                    <S.IconWrapper>
                      <img
                        src={info.iconName}
                        alt="icon"
                        style={{ width: '100%' }}
                      />
                    </S.IconWrapper>
                    <PublicText
                      value={info.title}
                      style={{
                        marginBottom: '10px',
                        fontWeight: 'bold',
                        fontSize: '22px',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: i === 2 ? 'flex-start' : 'center',
                      }}
                    >
                      {texts.map(text => (
                        <PublicText
                          key={nanoid()}
                          style={{ textAlign: 'center' }}
                          value={text}
                        />
                      ))}
                    </div>
                  </PublicBox>
                );
              })}
            </S.Infos>
          </S.Contents>
        </S.Container>
      )}
      <ReportSpinner />
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </>
  );
};

export default Startag;
