import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { BasicTable, TableOptions } from '@atoms/tables';
import { Spinner, Modal, ReportSpinner } from '@atoms/utils';
import { PublicBoxWithTitle } from '@molecules/boxes';
import { TableTabButtons } from '@molecules/buttons';
import MainTemplate from '@templates/MainTemplate';
import { useGetHotHashtagsQuery } from '@features/api';
import { HOTHASHTAG_ICONS, tableCodes as CODE } from '@constants';
import {
  useReport,
  useModal,
  useServiceItemCheckApi,
  useMounted,
} from '@hooks';
import { addCommaToNumber } from '@utils';

const HotHashtag: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { data } = useGetHotHashtagsQuery('hot');
  const {
    isShown,
    modalContent,
    onModalOpen,
    onModalClose,
    openServiceErrorModal,
  } = useModal();
  const {
    exportHotHashtag,
    errorCode: reportErrorCode,
    setErrorCode: setReportErrorCode,
  } = useReport();
  const { checkIsReportDownloaded } = useServiceItemCheckApi();
  const { isMounted } = useMounted();

  const [category, setCategory] = useState<string>('increase_rate');
  const [tableData, setTableData] = useState<TableSet | null>(null);

  const hotHashtagTable: TableSets = {
    increase_rate: {
      tableCode: CODE.HT,
      title: t('translation.HOT_POSTING_INCREASE_RATE'),
      titleIcon: HOTHASHTAG_ICONS.increase,
      columns: [
        t('translation.TABLE_RANKING'), // 순위
        t('translation.TABLE_HASHTAG'), // 해시태그,
        t('translation.TABLE_PREVIOUS_DAY_POSTING_INCREASE_RATE'), // 전일대비 게시물 증가율 (%)
      ],
    },
    increase_amount_daily: {
      tableCode: CODE.HT,
      title: t('translation.HOT_DAILY_POSTING_INCREASE_COUNT'),
      titleIcon: HOTHASHTAG_ICONS.daily,
      columns: [
        t('translation.TABLE_RANKING'), // 순위
        t('translation.TABLE_HASHTAG'), // 해시태그,
        t('translation.TABLE_POSTING_INCREASE_COUNT'), // 게시물 증가수
      ],
    },
    amount: {
      tableCode: CODE.HT,
      title: t('translation.HOT_POSTING_COUNT'),
      titleIcon: HOTHASHTAG_ICONS.amount,
      columns: [
        t('translation.TABLE_RANKING'), // 순위
        t('translation.TABLE_HASHTAG'), // 해시태그,
        t('translation.TABLE_POSTING_COUNT'), // 게시물 수
      ],
    },
  };

  useEffect(() => {
    setTableData(prevState => {
      return {
        ...prevState,
        tableCode: hotHashtagTable[category].tableCode,
        title: hotHashtagTable[category].title,
        columns: hotHashtagTable[category].columns,
        data: data && data[category],
      };
    });
  }, [data, category]);
  useEffect(() => {
    if (isMounted && reportErrorCode !== '') {
      openServiceErrorModal(reportErrorCode);
    }
    setReportErrorCode('');
  }, [reportErrorCode]);

  const handleTabButtonClick = (target: string) => {
    setCategory(target);
  };
  const handleReportSave = async () => {
    const responseData = await checkIsReportDownloaded('hot', 0);

    if (responseData) {
      const isReportDownload = true;
      const executedApi = (recipient = '') => {
        exportHotHashtag(data.update_date, recipient, {
          increase_rate: data.increase_rate,
          increase_amount_daily: data.increase_amount_daily,
          amount: data.amount,
        });
      };
      const message = t('translation.PREMIUM_SERVICE');
      const subMessage = [
        `${t('translation.PREMIUM_SERVICE_TEXT3')} ${addCommaToNumber(
          responseData.price
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ];
      const firstButtonText = t('translation.REPORT_SAVE_BUTTON');
      onModalOpen({
        onFirstButtonClick: executedApi,
        isReportDownload,
        message,
        subMessage,
        firstButtonText,
      });
    }
  };

  if (!data) {
    return <Spinner />;
  }

  return (
    <>
      <MainTemplate hasSearchInput={false}>
        <div>
          <TableOptions
            updateDate={data.update_date}
            onSaveReport={handleReportSave}
          />
          <TableTabButtons
            category={category}
            tabNames={[
              {
                id: nanoid(),
                // kr: '게시물 증가율',
                kr: t('translation.POSTING_INCREASE_RATE'),
                en: 'increase_rate',
              },
              {
                id: nanoid(),
                // kr: '1일 증가 수',
                kr: t('translation.DAY_INCREASE_COUNT'),
                en: 'increase_amount_daily',
              },
              {
                id: nanoid(),
                // kr: '게시물 수',
                kr: t('translation.POSTING_COUNT'),
                en: 'amount',
              },
            ]}
            onTabButtonClick={handleTabButtonClick}
          />
          <PublicBoxWithTitle
            titleIcon={hotHashtagTable[category].titleIcon}
            title={hotHashtagTable[category].title}
            width={1460}
            height={820}
          >
            <BasicTable tableData={tableData} tbodyHeight={710} />
          </PublicBoxWithTitle>
        </div>
      </MainTemplate>
      <ReportSpinner />
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </>
  );
};
export default HotHashtag;
