import type { FC } from 'react';
import { PublicText } from '@atoms/texts';
import * as S from './styles';

interface Props {
  iconName: string;
  width: number;
  height: number;
  value?: any;
  label?: string | undefined;
}

export const SmallPublicBoxWithIcon: FC<Props> = ({
  iconName,
  width,
  height,
  value = '',
  label = '',
}) => {
  return (
    <S.SmallBox width={width} height={height}>
      <S.Image src={iconName} alt="icon" />
      <S.Gap width={10} />
      <S.Content>
        <PublicText
          style={{
            color: '#000000',
            fontWeight: 'bold',
            fontSize: '28px',
          }}
          value={value}
        />
        <PublicText
          style={{
            color: '#A0A5B7',
            fontSize: '18px',
          }}
          value={label}
        />
      </S.Content>
    </S.SmallBox>
  );
};
