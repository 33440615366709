import styled from 'styled-components';
import background from '@assets/images/MainInit_background@2x.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1560px;
  height: 100vh;
  background-image: url(${background});
  background-size: cover;
`;
export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
`;
