interface PaginationPrevButtonProps {
  handlePaginatePrev: () => void;
}

export const PaginationPrevButton = ({
  handlePaginatePrev,
}: PaginationPrevButtonProps) => {
  return (
    <svg
      style={{
        cursor: 'pointer',
      }}
      onClick={handlePaginatePrev}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g id="그룹_3756" data-name="그룹 3756" transform="translate(-854 -1470)">
        <g
          id="사각형_756"
          data-name="사각형 756"
          transform="translate(854 1470)"
          fill="#fff"
          stroke="#a0a5b7"
          strokeWidth="1"
          opacity="0.4"
        >
          <rect width="28" height="28" stroke="none" />
          <rect x="0.5" y="0.5" width="27" height="27" fill="none" />
        </g>
        <path
          id="패스_1529"
          data-name="패스 1529"
          d="M3253.786-434.509l-5.568,4.872,5.568,4.872"
          transform="translate(-2383.001 1913.636)"
          fill="none"
          stroke="#a0a5b7"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
