import { useMediaQuery } from 'react-responsive';

export function useResponsive() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ maxWidth: 991, minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767, minWidth: 0 });
  const isAllowed = useMediaQuery({ minWidth: 992 });
  return {
    isDesktop,
    isTablet,
    isMobile,
    isAllowed,
  };
}
