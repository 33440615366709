export const tableCodes = {
  SF: 'Startag Four Columns',
  SFV: 'Startag Five Columns',
  TE: 'TopAccounts Eight Columns',
  TS: 'TopAccounts Six Columns',
  HT: 'HotHashtag Three Columns',
  ESV: 'ExposedAccount Seven Columns',
  EN: 'ExposedAccount Nine Columns',
  NT: 'Notice Three Columns',
  CF: 'ChargeHistory Five Columns',
  PE: 'PurchaseHistory Eight Columns',
};
