import styled from 'styled-components';
import { SmallPublicBox, PublicBox } from '@atoms/boxes';
import { BoldText, MediumText } from '@atoms/texts';

export const SmallBox = styled(SmallPublicBox)``;
export const Image = styled.img``;
export const Box = styled(PublicBox)`
  position: relative;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Value = styled(BoldText)``;
export const Label = styled(MediumText)``;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
export const Title = styled(BoldText)`
  /* max-width: 300px; */
  word-break: break-all;
`;

export const Gap = styled.div<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px;` : '100%')};
  height: ${({ height }) => (height ? `${height}px;` : '100%')};
`;
