import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorCodes as ERROR } from '@constants';

export const useModal = () => {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState({});
  const onModalClose = () => setIsShown(false);

  const onModalOpen = (content: any) => {
    const temp = content;
    if (!content.onClickAction) {
      temp.onClickAction = onModalClose;
    }
    setModalContent(temp);
    setIsShown(true);
  };
  const openServiceErrorModal = (errorCode: any = '') => {
    if (errorCode === ERROR.LESS_POINT) {
      onModalOpen({
        onFirstButtonClick: () => {
          window.open('http://pf.kakao.com/_pzfsb/chat');
        },
        // message: '포인트가 부족합니다',
        message: t('translation.POINT_LACK'),
        // subMessage: ['포인트 충전을 원하실 경우 아래 버튼을 눌러주세요.'],
        subMessage: [t('translation.POINT_LACK_TEXT')],
        // firstButtonText: '충전문의',
        firstButtonText: t('translation.CHARGE_ASK_BUTTON'),
      });
    } else if (errorCode === ERROR.THIRDPARTY_PARSING) {
      onModalOpen({
        onFirstButtonClick: onModalClose,
        // message: '검색할 수 없는 키워드입니다',
        message: t('translation.NO_SEARCH_KEYWORD_TITLE'),
        // subMessage: [
        //   '게시물 수가 적거나 데이터가 수집되지 않아',
        //   '검색 및 분석할 수 없는 키워드입니다.',
        // ],
        subMessage: [
          t('translation.NO_SEARCH_KEYWORD_TEXT1'),
          t('translation.NO_SEARCH_KEYWORD_TEXT2'),
        ],
        // firstButtonText: '확인',
        firstButtonText: t('translation.OK_BUTTON'),
      });
    } else {
      onModalOpen({
        onFirstButtonClick: onModalClose,
        // message: '구매가 정상적으로 완료되지 않았습니다',
        message: t('translation.PURCHASE_ERROR_TITLE'),
        // subMessage: [
        //   '일시적인 오류로 인해 구매에 실패하였습니다.',
        //   '오류가 반복해서 발생할 경우 고객센터로 문의바랍니다.',
        // ],
        subMessage: [
          t('translation.PURCHASE_ERROR_TEXT1'),
          t('translation.PURCHASE_ERROR_TEXT2'),
        ],
        // firstButtonText: '확인',
        firstButtonText: t('translation.OK_BUTTON'),
      });
    }
  };

  return {
    isShown,
    modalContent,
    onModalOpen,
    onModalClose,
    openServiceErrorModal,
  };
};
