import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Negative from '@assets/images/Email_expired@2x.png';
import Positive from '@assets/images/Email_completed@2x.png';
import { useLocation, useHistory } from 'react-router-dom';
import {
  useEmailAuthenthicationQuery,
  useResendExpiredEmailAuthenticationMutation,
} from '@features/api/';
import { isFetchBaseQueryErrorType } from '@utils';
import { errorCodes as ERROR } from '@constants';
import { Modal } from '@atoms/utils';
import { useModal } from '@hooks';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f9fbff;
`;
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Image = styled.img`
  margin-bottom: 20px;
  width: 70px;
  height: 70px;
`;
const MainMessage = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #555555;
`;
const SubMessage = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #555555;
`;
const Button = styled.button`
  margin-top: 34px;
  width: 200px;
  height: 50px;
  border: 0;
  background-color: #4a5bfd;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;

type statusType = 'expired' | 'unable' | 'alreadyDone' | 'completed';

const Email = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<statusType | null>(null);
  const [resendExpiredEmailAuthentication] =
    useResendExpiredEmailAuthenticationMutation();
  const { isShown, modalContent, onModalOpen, onModalClose } = useModal();

  const history = useHistory();
  const location = useLocation().pathname;
  const hashValue = location.split('/')[2];

  const res = useEmailAuthenthicationQuery({
    hashValue,
  });

  const handleButtonClick = () => {
    switch (status) {
      case 'expired':
        resendExpiredEmailAuthentication({
          hash_value: hashValue,
        });
        onModalOpen({
          onFirstButtonClick: () => {
            history.push('/');
          },
          message: t('translation.AUTH_EMAIL'),
          subMessage: [
            t('translation.AUTH_EMAIL_TEXT1'), // '도착한 메일의 링크를 누르면 계정 인증이 완료됩니다.',
            t('translation.AUTH_EMAIL_TEXT2'), // '인증을 마친 후 인스타트렌드의 서비스를 이용해보세요!',
          ],
          firstButtonText: t('translation.OK_BUTTON'),
        });
        break;
      case 'unable':
        window.open('http://pf.kakao.com/_pzfsb/chat');
        break;
      default:
        history.push('/');
    }
  };

  useEffect(() => {
    if (res.error) {
      if (isFetchBaseQueryErrorType(res.error)) {
        if ((res.error.data as any).code === ERROR.IS_ACTIVE_USER) {
          // 이미 활성화된 회원
          setStatus('alreadyDone');
        }
        if ((res.error.data as any).code === ERROR.EXPIRATION_EMAIL_AUTH) {
          // 해당 메일은 인증이 만료되었습니다.
          setStatus('expired');
        }
        if ((res.error.data as any).code === ERROR.UNACTIVE_USER) {
          // 인증할 수 없는 계정입니다.
          setStatus('unable');
        }
      }
    } else {
      // 이메일 인증이 완료되었습니다.
      setStatus('completed');
    }
  }, [res.isSuccess, res.isError]);
  return (
    <Container>
      <Contents>
        <Image
          src={
            status === 'expired' || status === 'unable' ? Negative : Positive
          }
        />
        <MainMessage>
          {
            status === 'expired'
              ? t('translation.AUTH_EMAIL_EXPIRATION') // 해당 메일은 인증이 만료되었습니다
              : status === 'unable'
              ? t('translation.NOT_AUTH_ACCOUNT') // '인증할 수 없는 계정입니다'
              : status === 'alreadyDone'
              ? t('translation.SUCCESS_AUTH_ACCOUNT') // '인증이 완료된 계정입니다'
              : t('translation.SUCCESS_AUTH_EMAIL') // '이메일 인증이 완료되었습니다'
          }
        </MainMessage>
        <SubMessage>
          {
            status === 'expired'
              ? t('translation.AUTH_EMAIL_EXPIRATION_TEXT1') // '이 인증메일의 링크는 만료되어 사용할 수 없습니다.'
              : status === 'unable'
              ? t('translation.NOT_AUTH_ACCOUNT_TEXT1') // '해당 계정은 현재 인스타트렌드 이용이 불가합니다.'
              : status === 'alreadyDone'
              ? t('translation.SUCCESS_AUTH_ACCOUNT_TEXT1') // '이미 해당 메일 주소로 인증이 완료되었습니다.'
              : t('translation.SUCCESS_AUTH_EMAIL_TEXT1') // '이제부터 인스타트렌드가 제공하는 서비스를 이용할 수 있습니다.'
          }
        </SubMessage>
        <SubMessage>
          {
            status === 'expired'
              ? t('translation.AUTH_EMAIL_EXPIRATION_TEXT2') // '인증 메일 재전송이 필요할 경우 아래 버튼을 눌러주세요.'
              : status === 'unable'
              ? t('translation.NOT_AUTH_ACCOUNT_TEXT2') // '자세한 사항은 고객센터로 문의해주세요.'
              : t('translation.SUCCESS_AUTH_ACCOUNT_TEXT2') // '로그인 후, 똑똑한 빅데이터가 제공하는 마케팅 솔루션을 체험해보세요!'
          }
        </SubMessage>
        <Button onClick={handleButtonClick}>
          {
            status === 'expired'
              ? t('translation.RESEND_BUTTON') // '재전송'
              : status === 'unable'
              ? t('translation.COUSTOMER_SERVICE_CENTER') //  '고객센터'
              : status === 'alreadyDone'
              ? t('translation.OK_BUTTON') // '확인'
              : t('translation.LOGIN_BUTTON') // '로그인'
          }
        </Button>
      </Contents>
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </Container>
  );
};
export default Email;
