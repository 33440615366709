import { Route, Redirect } from 'react-router-dom';
import { useTypedSelector, RootState } from '@features/rootStore';

const ErrorRoute = ({ ...rest }: any) => {
  const { accessToken } = useTypedSelector(({ rootReducer }: RootState) => ({
    accessToken: rootReducer.authReducers.accessToken,
  }));
  return (
    <Route {...rest}>
      {accessToken ? (
        <Redirect to={{ pathname: '/startag' }} />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )}
    </Route>
  );
};

export default ErrorRoute;
