import styled from 'styled-components';

export const MediumText = styled.p<TextStyle>`
  font-weight: 500;
  ${({ theme, desktopFontSize }) =>
    desktopFontSize &&
    theme.media.desktop`font-size: ${theme.fontSize[desktopFontSize]}px;`}
  ${({ theme, tabletFontSize }) =>
    tabletFontSize &&
    theme.media.tablet`font-size: ${theme.fontSize[tabletFontSize]}px;`}
  color: ${({ color, theme }) => {
    if (color[0] === '#') {
      return color;
    }
    return theme.color[color as string];
  }};
`;
