export const SuitabilityNormal: React.FC<SVGStyle> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="220"
      height="18"
      viewBox="0 0 220 18"
    >
      <g
        id="그룹_3739"
        data-name="그룹 3739"
        transform="translate(-1010 -1221)"
      >
        <line
          id="선_21"
          data-name="선 21"
          x2="204"
          transform="translate(1017.5 1229.5)"
          fill="none"
          stroke="#f2f2f2"
          strokeWidth="3"
        />
        <g
          id="타원_18"
          data-name="타원 18"
          transform="translate(1111 1221)"
          fill="#fff"
          stroke="#8d97ff"
          strokeWidth="5"
        >
          <circle cx="9" cy="9" r="9" stroke="none" />
          <circle cx="9" cy="9" r="6.5" fill="none" />
        </g>
        <circle
          id="타원_19"
          data-name="타원 19"
          cx="7"
          cy="7"
          r="7"
          transform="translate(1010 1223)"
          fill="#f2f2f2"
        />
        <circle
          id="타원_20"
          data-name="타원 20"
          cx="7"
          cy="7"
          r="7"
          transform="translate(1216 1223)"
          fill="#f2f2f2"
        />
      </g>
    </svg>
  );
};
