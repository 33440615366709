import { Redirect, Route } from 'react-router-dom';
import { useTypedSelector, RootState } from '@features/rootStore';

const ConsoleRoute = ({ children, ...rest }: any) => {
  const { accessToken } = useTypedSelector(({ rootReducer }: RootState) => ({
    accessToken: rootReducer.authReducers.accessToken,
  }));
  return (
    <Route {...rest}>
      {accessToken ? <Redirect to={{ pathname: '/startag' }} /> : children}
    </Route>
  );
};
export default ConsoleRoute;
