import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { addCommaToNumber } from '@utils';

const BlockDimmed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
`;
const IncludedModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 42px 20px;
  width: 500px;
  height: 224px;
  border-radius: 20px;
  background-color: #fff;
`;
const Messages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;
const Message = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
`;
const SubMessage = styled.div`
  font-size: 16px;
  & > p {
    text-align: center;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;
const GetNewButton = styled.button<{ isDisabled: boolean }>`
  margin-right: 15px;
  width: 200px;
  height: 50px;
  border: 0;
  border-radius: 10px;
  background-color: ${({ isDisabled }) => (isDisabled ? '#CECECE' : '#4A5BFD')};
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ isDisabled }) =>
      isDisabled ? '#CECECE' : '#8D97FF'};
  }
`;
const GetExistButton = styled.button<{ isDisabled: boolean }>`
  width: 200px;
  height: 50px;
  border: 0;
  border-radius: 10px;

  background-color: ${({ isDisabled }) => (isDisabled ? '#CECECE' : '#4A5BFD')};
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ isDisabled }) =>
      isDisabled ? '#CECECE' : '#8D97FF'};
  }
`;

interface Props {
  subMessage: string;
  itemCnt: number;
  hashtag: string;
  onGetNewButtonClick: () => void;
  onGetExistButtonClick: () => void;
  isBlockGetNewDisabled: boolean;
  isChargedItemExist: boolean;
}
export const Block = ({
  subMessage,
  itemCnt,
  hashtag,
  onGetNewButtonClick,
  onGetExistButtonClick,
  isBlockGetNewDisabled,
  isChargedItemExist,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <BlockDimmed />
      <IncludedModal>
        <Messages>
          <Message>
            <span
              style={{
                color: '#4A5BFD',
                fontWeight: 'bold',
              }}
            >
              {hashtag}
            </span>
            {t('translation.WORD_INCLUDE_HASHTAG_ALERT_TITLE1')}
          </Message>
          <Message>
            <span
              style={{
                color: '#4A5BFD',
                fontWeight: 'bold',
              }}
            >
              {addCommaToNumber(itemCnt)}
              {t('translation.UNIT_COUNT1')}
            </span>{' '}
            {t('translation.WORD_INCLUDE_HASHTAG_ALERT_TITLE2')}
          </Message>
          <SubMessage>{subMessage}</SubMessage>
        </Messages>
        <Buttons>
          <GetNewButton
            isDisabled={isBlockGetNewDisabled}
            onClick={onGetNewButtonClick}
          >
            {t('translation.NEW_ANALYSIS_RESULT_INQUIRY')}
          </GetNewButton>
          <GetExistButton
            isDisabled={!isChargedItemExist}
            onClick={onGetExistButtonClick}
          >
            {t('translation.ORIGIN_ANALYSIS_RESULT_INQUIRY')}
          </GetExistButton>
        </Buttons>
      </IncludedModal>
    </>
  );
};
