import * as S from './styles';
import { TableTabButton } from '../../atoms/buttons';

interface Props {
  category: string;
  tabNames: NameSets;
  onTabButtonClick: (target: string) => void;
}

export const TableTabButtons = ({
  category,
  tabNames,
  onTabButtonClick,
}: Props) => {
  const buttons = tabNames.map((set: NameSet) => (
    <TableTabButton
      type="button"
      key={set.id}
      name={set.kr}
      isActive={category === set.en}
      onClick={() => onTabButtonClick(set.en)}
    />
  ));
  return <S.TableTabButtons>{buttons}</S.TableTabButtons>;
};
