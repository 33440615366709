import * as S from './styles';

interface Props {
  value: string;
  style: Record<string, string>;
}

export const PublicText = ({ value, style = {} }: Props) => {
  return <S.Text style={style}>{value}</S.Text>;
};
