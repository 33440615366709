import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
import jp from '@language/jp.json';
import ko from '@language/ko.json';

i18n.use(initReactI18next).init({
  resources: {
    ko: {
      translation: ko,
    },
    jp: {
      translation: jp,
    },
  },
  fallbackLng: 'ko',
  debug: true,
  keySeparator: '.',
  interpolation: { escapeValue: false },
});

export const ApiCheckContry = async () => {
  const res = await axios
    .get('https://api.ip.pe.kr/json')
    .then(response => {
      return response.data.country_code;
    })
    .catch(error => {
      return error;
    });

  return res;
};

const onCheckContry = async () => {
  const res = await ApiCheckContry();

  localStorage.setItem('language', res);

  const id = localStorage.getItem('id');

  if (id === `${process.env.REACT_APP_JP_ID}`) {
    i18n.changeLanguage('jp');
    return;
  }

  i18n.changeLanguage(!res || res === 'KR' ? 'ko' : 'jp');
};

if (window.location.pathname !== '/') {
  onCheckContry();
}

export default i18n;
