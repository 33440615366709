import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  ${({ theme }) => theme.media.desktop`width: fit-content;`}
  ${({ theme }) => theme.media.mobile`width: 100vw;`}
  width: fit-content;
  height: 100%;
  /* 임의로 설정한 min-height */
  ${({ theme }) => theme.media.desktop`min-height: 900px;`}
  ${({ theme }) => theme.media.desktop`min-height: 100vh;`}
`;
export const Main = styled.main`
  margin-left: 360px;
  width: 1560px;
  height: 100%;
  background-color: #f9fbff;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  padding: 0 45px;
  width: 360px;
  height: 100%;
  min-height: 900px;
  border-radius: 0 30px 30px 0;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
`;
export const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  width: 100%;
  height: 134px;
`;
export const UserInfo = styled.div`
  margin-bottom: 20px;
  padding-left: 20px;
`;
export const Point = styled.div`
  display: flex;
`;
export const NavServices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #d0d2d9;
  border-bottom: 1px solid #d0d2d9;
`;
export const NavService = styled.div`
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;
  color: #a0a5b7;
  &:last-child {
    margin: 0;
  }
  &:hover {
    background-color: #4a5bfd;
    box-shadow: 0 10px 10px rgba(74, 91, 253, 0.1);
    color: #fff;
  }
`;

export const Accordion = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const AccordionTitle = styled.div<{ isCollapse: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 10px;
  background-color: ${({ isCollapse }) => (isCollapse ? '#F9FBFF' : '#fff')};
  box-shadow: ${({ isCollapse }) =>
    isCollapse ? '0 10px 10px rgba(74, 91, 253, 0.05)' : 0};
  font-weight: bold;
  color: ${({ isCollapse }) => (isCollapse ? '#4A5BFD' : '#A0A5B7')};
  cursor: pointer;
  &:hover {
    background-color: #f9fbff;
    color: #4a5bfd;
    box-shadow: 0 10px 10px rgba(74, 91, 253, 0.05);
  }
`;
export const AccordionArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
`;
export const AccordionItems = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 14px 0;
`;
export const AccordionItem = styled.div`
  width: 100%;
  color: #555555;
  & ~ & {
    margin-top: 10px;
  }
  &:hover {
    color: #4a5bfd;
  }
`;
export const NavSecondary = styled.div`
  margin-top: 6px;
`;

export const Logout = styled.button`
  position: absolute;
  top: 140px;
  right: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 54px;
  height: 22px;
  border: 1px solid #cecece;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 11px;
  color: #a1a1a1;
  padding: 2px 4px;
  cursor: pointer;
  &:hover {
    border-color: #555555;
  }
`;

export const NotAllowed = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f9fbff;
`;
