import { ButtonHTMLAttributes } from 'react';

export const Modal_close = ({
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        border: '0',
        background: '#FFF',
      }}
      {...props}
    >
      <svg
        id="구성_요소_72_5"
        data-name="구성 요소 72 – 5"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
      >
        <rect
          id="사각형_1392"
          data-name="사각형 1392"
          width="28"
          height="28"
          fill="none"
        />
        <g
          id="그룹_5232"
          data-name="그룹 5232"
          transform="translate(-1139 -449)"
        >
          <path
            id="패스_4870"
            data-name="패스 4870"
            d="M13,0A13,13,0,1,0,26,13,12.988,12.988,0,0,0,13,0"
            transform="translate(1140 450)"
            fill="none"
          />
          <path
            id="패스_4871"
            data-name="패스 4871"
            d="M18,16.167,16.167,18,11.5,13.333,6.833,18,5,16.167,9.667,11.5,5,6.833,6.833,5,11.5,9.667,16.167,5,18,6.833,13.333,11.5Z"
            transform="translate(1141.5 451.5)"
            fill="#a1a1a1"
          />
        </g>
      </svg>
    </button>
  );
};
