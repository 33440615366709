import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from 'react-table';
import { useHistory } from 'react-router';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { TABLE_PAGINATION } from '@constants';

const StyledTr = styled.tr`
  background-color: #fff;
  &:hover {
    background-color: #f9fbff;
  }
`;

function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: any;
  setGlobalFilter: any;
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(VALUE => {
    setGlobalFilter(VALUE || undefined);
  }, 200);

  return (
    <span>
      <input
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={t('translation.NOTICE_SEARCH_INPUT_PLACEHOLDER')}
        style={{
          padding: '20px 11px',
          width: '360px',
          height: '50px',
          backgroundColor: '#fff',
          fontSize: '18px',
          border: '1px solid #CECECE',
          outline: 'none',
        }}
      />
    </span>
  );
}

interface NoticeTable {
  no: string;
  title: string;
  date: string;
}
interface Props {
  columns: any;
  data: any;
}

export const Table = ({ columns, data }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable<NoticeTable>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  useEffect(() => {
    setPageSize(15);
  }, []);

  const addedPage = Math.floor(pageIndex / 10) * 10;
  const shownPages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    .map(pageNum => pageNum + addedPage)
    .filter(pageNum => pageNum <= pageCount);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            marginBottom: '20px',
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {t('translation.TOTAL_NOTICE_COUNT')} : {rows.length}
          {t('translation.UNIT_COUNT2')}
        </div>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <table
        {...getTableProps()}
        key={nanoid()}
        style={{
          position: 'relative',
          margin: '0 auto',
          width: '1420px',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <StyledTr
              {...headerGroup.getHeaderGroupProps()}
              key={nanoid()}
              style={{
                height: '40px',
              }}
            >
              {headerGroup.headers.map((column, i) => {
                const widths = [
                  '80px',
                  '342px',
                  '240px',
                  '160px',
                  '160px',
                  '178px',
                  '100px',
                  '100px',
                ];
                const width = widths[i];
                return (
                  <th
                    {...column.getHeaderProps()}
                    key={nanoid()}
                    style={{
                      width,
                      background: '#F2F2F2',
                      color: '#555555',
                      fontWeight: 'bold',
                    }}
                  >
                    {column.render('Header')}
                  </th>
                );
              })}
            </StyledTr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <StyledTr
                {...row.getRowProps()}
                key={nanoid()}
                style={{
                  height: '40px',
                  borderBottom: '1px dashed #E8E8E8',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  history.push(`/notice/${row.original.detailIdx}`)
                }
              >
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={nanoid()}
                      style={{
                        textAlign: 'center',
                        fontWeight: row.original.isPin ? 'bold' : 'normal',
                        color: row.original.isPin ? '#4A5BFD' : '#000000',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </StyledTr>
            );
          })}
        </tbody>
      </table>
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          fontSize: '14px',
        }}
      >
        Per Page
        <select
          style={{
            marginLeft: '10px',
            width: '60px',
            height: '30px',
            borderColor: '#cecece',
            fontSize: '14px',
          }}
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[15, 25, 50, 100].map(PAGESIZE => (
            <option key={PAGESIZE} value={PAGESIZE}>
              {PAGESIZE}
            </option>
          ))}
        </select>
      </div>
      <div
        className="pagination"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <button
          type="button"
          style={{
            marginRight: '6px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => gotoPage(0)}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.gotofirst}
            alt="go to first"
          />
        </button>{' '}
        <button
          type="button"
          style={{
            marginRight: '20px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => previousPage()}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.prev}
            alt="prev"
          />
        </button>
        {shownPages.map((pageNum, i) => {
          return (
            <button
              type="button"
              key={nanoid()}
              style={{
                marginRight: i === shownPages.length - 1 ? 0 : '2px',
                width: '24px',
                height: '24px',
                border: 0,
                borderRadius: '5px',
                background: pageNum === pageIndex + 1 ? '#555555' : 0,
                fontSize: '14px',
                color: pageNum === pageIndex + 1 ? '#fff' : '#000',
                cursor: 'pointer',
              }}
              onClick={() => gotoPage(pageNum - 1)}
            >
              {pageNum}
            </button>
          );
        })}
        <button
          type="button"
          style={{
            marginLeft: '20px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => nextPage()}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.next}
            alt="next"
          />
        </button>{' '}
        <button
          type="button"
          style={{
            marginLeft: '6px',
            width: '24px',
            height: '24px',
            background: 0,
            border: 0,
            cursor: 'pointer',
          }}
          onClick={() => gotoPage(pageCount - 1)}
        >
          <img
            style={{ width: '100%' }}
            src={TABLE_PAGINATION.gotolast}
            alt="go to last"
          />
        </button>{' '}
      </div>
    </>
  );
};
