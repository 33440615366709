const AccordionArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g id="그룹_5073" data-name="그룹 5073" transform="translate(-20 -11)">
        <g
          id="그룹_3830"
          data-name="그룹 3830"
          transform="translate(20 11.037)"
        >
          <rect
            id="사각형_282"
            data-name="사각형 282"
            width="18"
            height="18"
            transform="translate(0 -0.037)"
            fill="none"
            opacity="0.2"
          />
        </g>
        <path
          id="패스_4030"
          data-name="패스 4030"
          d="M10.59,0,6,4.58,1.41,0,0,1.409l6,6,6-6Z"
          transform="translate(35 23.705) rotate(180)"
          fill="#4a5bfd"
        />
      </g>
    </svg>
  );
};
export default AccordionArrowUp;
