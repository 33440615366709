import type { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PublicSearchInput } from '@atoms/inputs';
import * as S from './MainTemplate.style';

interface Props {
  hasSearchInput: boolean;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  inputValue?: string;
  isAccount?: boolean;
  onSearchClick?: () => void;
  onKeyPress?: (e: any) => void;
  children?: React.ReactNode;
}

const MainTemplate: FC<Props> = ({
  hasSearchInput,
  onInputChange,
  inputValue,
  isAccount,
  onSearchClick,
  onKeyPress,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      {hasSearchInput ? (
        <>
          <PublicSearchInput
            style={{
              width: '360px',
              height: '50px',
              border: '0',
              borderRadius: '10px',
              backgroundColor: '#F1F5FE',
              fontSize: '18px',
              inputWidth: '276px',
            }}
            placeholder={
              isAccount
                ? t('translation.INPUT_PLACEHOLDER_ACCOUNT2')
                : t('translation.INPUT_PLACEHOLDER_HASHTAG2')
            }
            value={inputValue}
            onInputChange={onInputChange}
            onSearchClick={onSearchClick}
            onKeyPress={onKeyPress}
          />
          <S.Gap height={30} />
        </>
      ) : null}
      {children}
    </S.Container>
  );
};
export default MainTemplate;
