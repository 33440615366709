import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { PublicText } from '@atoms/texts';
import { PublicBox } from '@atoms/boxes';
import { TableOptions } from '@atoms/tables';
import { PublicSearchInput } from '@atoms/inputs';
import { BarChart } from '@atoms/apexcharts';
import { RechartLineChart } from '@atoms/recharts';
import {
  Gap,
  FlexContainer,
  Modal,
  Spinner,
  ReportSpinner,
} from '@atoms/utils';
import { SmallPublicBoxWithIcon, PublicBoxWithTitle } from '@molecules/boxes';
import MainTemplate from '@templates/MainTemplate';
import {
  SMALLPUBLICBOX_ICONS as SMALLBOXICON,
  MAININITTEMPALTE_LOGOS as LOGO,
  POSTINCREASE_DESCRIPTION as DESCRIPTION,
} from '@constants';
import {
  useModal,
  useMounted,
  useReport,
  useServiceItemCheckApi,
  useServiceApi,
} from '@hooks';
import { addCommaToNumber } from '@utils';
import * as S from './PostIncrease.Style';

// const basicInfosInit: BasicInfoSets = [
//   {
//     iconName: SMALLBOXICON.account_post,
//     label: t('translation.INQUIRY_DATE'),
//     width: 335,
//     height: 110,
//   },
//   {
//     iconName: SMALLBOXICON.exposedaccount_hashtag,
//     label: t('translation.해시태그'),
//     width: 335,
//     height: 110,
//   },
//   {
//     iconName: SMALLBOXICON.exposedaccount_post,
//     label: t('translation.POSTING_COUNT'),
//     width: 335,
//     height: 110,
//   },
//   {
//     iconName: SMALLBOXICON.postincrease_monthlyincrease,
//     label: t('translation.POSTING_INCREASE_TRANSITION_TITLE3_DESCRIPTION1'),
//     width: 335,
//     height: 110,
//   },
// ];
const PostIncrease: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  // ref
  const searchedHashtagRef = useRef<string>('');

  // hook
  const {
    isShown,
    modalContent,
    onModalOpen,
    onModalClose,
    openServiceErrorModal,
  } = useModal();
  const {
    exportPostIncrease,
    errorCode: reportErrorCode,
    setErrorCode: setReportErrorCode,
  } = useReport();
  const { checkKeywordIsOrdered, checkIsReportDownloaded } =
    useServiceItemCheckApi();
  const { getNew, getNewChargedItemState, getExist, getExistChargedItemState } =
    useServiceApi();
  const { isMounted } = useMounted();

  // state
  const [inputHashtag, setInputHashtag] = useState<string>('');
  const [serviceData, setServiceData] = useState<any>({
    updateDate: '',
    basicInfo: [
      {
        iconName: SMALLBOXICON.account_post,
        label: t('translation.INQUIRY_DATE'),
        width: 335,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.exposedaccount_hashtag,
        label: t('translation.HASHTAG'),
        width: 335,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.exposedaccount_post,
        label: t('translation.POSTING_COUNT'),
        width: 335,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.postincrease_monthlyincrease,
        label: t('translation.MONTHLY_AVERAGE_POSTING_INCREASE_COUNT'),
        width: 335,
        height: 110,
      },
    ],
    chartData: null,
    reportNo: 0,
  });
  const [itemPrice, setItemPrice] = useState(0);
  const [isTableOptionsGetNewDisabled, setIsTableOptionsGetNewDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    if (searchedHashtagRef.current === '') setInputHashtag('');
  }, [isShown]);
  useEffect(() => {
    if (isMounted && reportErrorCode !== '') {
      openServiceErrorModal(reportErrorCode);
    }
    setReportErrorCode('');
  }, [reportErrorCode]);

  function handleServiceDataSet(responseData: any) {
    const daysByAmountValues: number[] = [];
    const daysByAmountDates: string[] = [];
    const monthsByAmountValues: number[] = [];
    const monthsByAmountDates: string[] = [];
    const {
      update_date,
      search_date,
      hashtag,
      post,
      monthly_increase_average,
      days_by_amount,
      month_by_amount,
      post_log,
      no,
    } = responseData;
    days_by_amount.forEach(
      (e: { checked_day: string; daily_increase_amount: number }) => {
        let day = '';

        if (e.checked_day === '일') {
          day = t('translation.SUNDAY');
        }

        if (e.checked_day === '월') {
          day = t('translation.MONDAY');
        }

        if (e.checked_day === '화') {
          day = t('translation.TUESDAY');
        }

        if (e.checked_day === '수') {
          day = t('translation.WEDNESDAY');
        }

        if (e.checked_day === '목') {
          day = t('translation.THURSDAY');
        }

        if (e.checked_day === '금') {
          day = t('translation.FRIDAY');
        }

        if (e.checked_day === '토') {
          day = t('translation.SATURDAY');
        }

        daysByAmountValues.push(e.daily_increase_amount);
        daysByAmountDates.push(day);
      }
    );
    month_by_amount.forEach(
      (e: { month: string; monthly_increase_amount: number }) => {
        let month = '';

        if (e.month === '1월') {
          month = t('translation.JANUARY');
        }
        if (e.month === '2월') {
          month = t('translation.FEBRUARY');
        }
        if (e.month === '3월') {
          month = t('translation.MARCH');
        }
        if (e.month === '4월') {
          month = t('translation.APRIL');
        }
        if (e.month === '5월') {
          month = t('translation.MAY');
        }
        if (e.month === '6월') {
          month = t('translation.JUNE');
        }
        if (e.month === '7월') {
          month = t('translation.JULY');
        }
        if (e.month === '8월') {
          month = t('translation.AUGUST');
        }
        if (e.month === '9월') {
          month = t('translation.SEPTEMBER');
        }
        if (e.month === '10월') {
          month = t('translation.OCTOBER');
        }
        if (e.month === '11월') {
          month = t('translation.NOVEMBER');
        }
        if (e.month === '12월') {
          month = t('translation.DECEMBER');
        }

        monthsByAmountValues.push(e.monthly_increase_amount);
        monthsByAmountDates.push(month);
      }
    );

    const postLog = post_log.map(
      (e: { check_date: string; post_cnt: number }) => ({
        value: e.post_cnt,
        date: e.check_date,
      })
    );
    setServiceData((prev: any) => {
      return {
        updateDate: update_date,
        basicInfo: prev.basicInfo.map((info: any, i: number) => {
          if (i === 0)
            return {
              ...info,
              value: search_date.substring(0, 10),
            };
          if (i === 1) return { ...info, value: hashtag };
          if (i === 2) return { ...info, value: post };
          return { ...info, value: monthly_increase_average };
        }),
        chartData: {
          daysByAmount: {
            values: daysByAmountValues,
            dates: daysByAmountDates,
          },
          monthsByAmount: {
            values: monthsByAmountValues,
            dates: monthsByAmountDates,
          },
          log: postLog,
        },
        reportNo: no,
      };
    });
  }

  const handleSearchButtonClick = async () => {
    if (inputHashtag === '') return;
    try {
      if (
        inputHashtag.includes('\\') ||
        inputHashtag.includes('/') ||
        inputHashtag.includes('?') ||
        inputHashtag.includes('!') ||
        inputHashtag.includes('@') ||
        inputHashtag.includes('#')
      ) {
        throw Error;
      }
      const { status, order_info, price } = await checkKeywordIsOrdered(
        'post_increase',
        inputHashtag
      );
      setItemPrice(price);
      onModalOpen({
        onFirstButtonClick: async () => {
          const responseData = await getNew('post_increase', inputHashtag);
          if (responseData) {
            if (responseData.status >= 400) {
              openServiceErrorModal(responseData.data.code);
            } else {
              searchedHashtagRef.current = inputHashtag;
              handleServiceDataSet(responseData);
              setIsTableOptionsGetNewDisabled(true);
            }
          }
        },
        onSecondButtonClick: async () => {
          const responseData = await getExist(order_info);
          searchedHashtagRef.current = inputHashtag;
          handleServiceDataSet(responseData);
          setIsTableOptionsGetNewDisabled(status === -1);
        },
        message:
          status === -1
            ? t('translation.ALREADY_PURCHASE_PRODUCTION_TITLE')
            : t('translation.PREMIUM_SERVICE'),
        subMessage: [
          status === -1
            ? t('translation.ALREADY_NEW_ANALYSIS_RESULT_PURCHASE_TITLE')
            : `${t(
                'translation.ALREADY_NEW_ANALYSIS_RESULT_PURCHASE_TEXT'
              )} ${addCommaToNumber(price)}${t(
                'translation.PREMIUM_SERVICE_TEXT2'
              )}`,
        ],
        isTwoButton: true,
        isFirstButtonDisabled: status === -1,
        isSecondButtonDisabled: order_info === '0',
        firstButtonText: t('translation.NEW_ANALYSIS_RESULT_INQUIRY'),
        secondButtonText: t('translation.ORIGIN_ANALYSIS_RESULT_INQUIRY'),
      });
    } catch (e) {
      openServiceErrorModal();
    }
  };
  const handleGetNewChargedItemButtonClick = () => {
    if (isTableOptionsGetNewDisabled) return;
    onModalOpen({
      onFirstButtonClick: async () => {
        const responseData = await getNew('post_increase', inputHashtag);
        if (responseData) {
          if (responseData.status >= 400) {
            openServiceErrorModal(responseData.data.code);
          } else {
            searchedHashtagRef.current = inputHashtag;
            handleServiceDataSet(responseData);
            setIsTableOptionsGetNewDisabled(true);
          }
        }
      },
      message: t('translation.PREMIUM_SERVICE'),
      subMessage: [
        `${t('translation.PREMIUM_SERVICE_TEXT1')} ${addCommaToNumber(
          itemPrice
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ],
      firstButtonText: t('translation.NEW_DATA_ANALYSIS_BUTTON'),
    });
  };
  const handleReportSave = async () => {
    const responseData = await checkIsReportDownloaded(
      'post_increase',
      serviceData.reportNo
    );
    if (responseData) {
      const isReportDownload = true;
      const executedApi = (recipient = '') => {
        exportPostIncrease(
          searchedHashtagRef.current,
          serviceData.updateDate,
          recipient,
          serviceData.basicInfo[2].value as string,
          serviceData.basicInfo[3].value as string
        );
      };
      const message = t('translation.PREMIUM_SERVICE');
      const subMessage = [
        `${t('translation.PREMIUM_SERVICE_TEXT3')} ${addCommaToNumber(
          responseData.price
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ];
      const firstButtonText = t('translation.REPORT_SAVE_BUTTON');
      onModalOpen({
        onFirstButtonClick: executedApi,
        isReportDownload,
        message,
        subMessage,
        firstButtonText,
      });
    }
  };

  const onConvertLanguage = () => {
    const originList = {
      ...DESCRIPTION,
      title: t('translation.POSTING_INCREASE_TRANSITION'),
    };

    const res = originList.info.map((obj: any) => {
      let title = '';
      let content = '';

      if (obj.title === '게시물 증가 추이란?') {
        title = t('translation.POSTING_INCREASE_TRANSITION_TITLE1');
        content = t(
          'translation.POSTING_INCREASE_TRANSITION_TITLE1_DESCRIPTION1'
        );
      }

      if (obj.title === '추천 사용자') {
        title = t('translation.POSTING_INCREASE_TRANSITION_TITLE2');
        content = t(
          'translation.POSTING_INCREASE_TRANSITION_TITLE2_DESCRIPTION1'
        );
      }

      if (obj.title === '분석항목') {
        title = t('translation.POSTING_INCREASE_TRANSITION_TITLE3');
        content = t(
          'translation.POSTING_INCREASE_TRANSITION_TITLE3_DESCRIPTION1'
        );
      }

      return {
        ...obj,
        title,
        content,
      };
    });

    return {
      ...originList,
      info: res,
    };
  };

  if (getNewChargedItemState.isLoading || getExistChargedItemState.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {serviceData.updateDate &&
      serviceData.basicInfo &&
      serviceData.chartData ? (
        <MainTemplate
          hasSearchInput
          onInputChange={e => setInputHashtag(e.target.value)}
          inputValue={inputHashtag}
          onSearchClick={handleSearchButtonClick}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSearchButtonClick();
            }
          }}
        >
          <TableOptions
            isGetNewHave
            updateDate={serviceData.updateDate}
            onGetNewClick={handleGetNewChargedItemButtonClick}
            onSaveReport={handleReportSave}
            isGetNewDisabled={isTableOptionsGetNewDisabled}
          />
          <FlexContainer>
            {serviceData.basicInfo?.map((info: any, i: number) => (
              <SmallPublicBoxWithIcon
                key={nanoid()}
                iconName={info.iconName}
                width={info.width}
                height={info.height}
                value={
                  i === 2 || i === 3 ? addCommaToNumber(info.value) : info.value
                }
                label={info.label}
              />
            ))}
          </FlexContainer>
          <Gap height={40} />
          <FlexContainer>
            <PublicBoxWithTitle
              // title="요일별 게시물 증가량"
              title={t('translation.DAYS_POSTING_INCREASE_COUNT')}
              width={532}
              height={512}
            >
              <BarChart
                dates={serviceData.chartData.daysByAmount.dates}
                values={serviceData.chartData.daysByAmount.values}
              />
            </PublicBoxWithTitle>
            <PublicBoxWithTitle
              // title="월별 게시물 증가량"
              title={t('translation.MONTHLY_POSTING_INCREASE_COUNT')}
              width={888}
              height={512}
            >
              <BarChart
                dates={serviceData.chartData.monthsByAmount.dates}
                values={serviceData.chartData.monthsByAmount.values}
              />
            </PublicBoxWithTitle>
          </FlexContainer>
          <Gap height={40} />
          <PublicBoxWithTitle
            // title="포스트 증가 추이"
            title={t('translation.POST_INCREASE_TRANSITION')}
            width={1460}
            height={568}
          >
            <RechartLineChart data={serviceData.chartData.log} />
          </PublicBoxWithTitle>
        </MainTemplate>
      ) : (
        <S.Container>
          <S.Contents>
            <img
              style={{ width: '76px', height: '76px' }}
              src={LOGO.postincrease}
              alt="logo"
            />
            <Gap height={28} />
            <PublicText
              style={{ fontSize: '40px', fontWeight: 'bold' }}
              value={t('translation.POSTING_INCREASE_TRANSITION')}
            />
            <Gap height={32} />
            <PublicSearchInput
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSearchButtonClick();
                }
              }}
              onSearchClick={handleSearchButtonClick}
              onInputChange={e => setInputHashtag(e.target.value)}
              placeholder={t('translation.INPUT_PLACEHOLDER_HASHTAG')}
              value={inputHashtag}
              style={{
                width: '748px',
                height: '70px',
                borderRadius: '10px',
                fontSize: '20px',
                inputWidth: '638px',
              }}
            />
            <Gap height={70} />
            <S.Infos>
              {onConvertLanguage().info.map((info, i) => {
                const titles: any[] = info.title.split('/');
                const texts: any[] = info.content.split('/');
                return (
                  <PublicBox
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '30px 10px',
                    }}
                    width={236}
                    height={250}
                    key={nanoid()}
                  >
                    <S.IconWrapper>
                      <img
                        src={info.iconName}
                        alt="icon"
                        style={{ width: '100%' }}
                      />
                    </S.IconWrapper>
                    {titles.map(text => (
                      <PublicText
                        key={nanoid()}
                        value={text}
                        style={{ fontWeight: 'bold', fontSize: '22px' }}
                      />
                    ))}
                    <Gap height={10} />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: i === 2 ? 'flex-start' : 'center',
                      }}
                    >
                      {texts.map(text => (
                        <PublicText
                          key={nanoid()}
                          style={{ textAlign: 'center' }}
                          value={text}
                        />
                      ))}
                    </div>
                  </PublicBox>
                );
              })}
            </S.Infos>
          </S.Contents>
        </S.Container>
      )}
      <ReportSpinner />
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </>
  );
};
export default PostIncrease;
