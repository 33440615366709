import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { onConvertPopularPostingExposureSuitability } from 'common/functions';
import { PublicBox } from '@atoms/boxes';
import { PublicText } from '@atoms/texts';
import { TableOptions } from '@atoms/tables';
import { PublicSearchInput } from '@atoms/inputs';
import AreaChart from '@atoms/apexcharts/AreaChart';
import {
  Gap,
  FlexContainer,
  Modal,
  Spinner,
  ReportSpinner,
} from '@atoms/utils';
import { SmallPublicBoxWithIcon, PublicBoxWithTitle } from '@molecules/boxes';
import MainTemplate from '@templates/MainTemplate';
import {
  MAININITTEMPALTE_LOGOS as LOGO,
  SMALLPUBLICBOX_ICONS as SMALLBOXICON,
  ACCOUNT_DESCRIPTION as DESCRIPTION,
} from '@constants';
import {
  useModal,
  useReport,
  useMounted,
  useServiceApi,
  useServiceItemCheckApi,
} from '@hooks';
import { convertNumberNotation, addCommaToNumber } from '@utils';
import {
  ExposedHashtags,
  AccountSuitability,
  DetailSuitability,
  NoDataCase,
} from './components';
import * as S from './Account.style';

const dateInfo: BasicInfoSet = {
  iconName: SMALLBOXICON.account_post,
  label: '조회 날짜',
  width: 480,
  height: 110,
};

const Account: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const searchedAccountRef = useRef<string>('');

  const {
    isShown,
    modalContent,
    onModalOpen,
    onModalClose,
    openServiceErrorModal,
  } = useModal();
  const {
    exportAccount,
    errorCode: reportErrorCode,
    setErrorCode: setReportErrorCode,
  } = useReport();
  const { checkKeywordIsOrdered, checkIsReportDownloaded } =
    useServiceItemCheckApi();
  const {
    getNewAccount,
    getNewAccountItemState,
    getExist,
    getExistChargedItemState,
  } = useServiceApi();
  const { isMounted } = useMounted();

  const [inputAccount, setInputAccount] = useState<string>('');
  const [serviceData, setServiceData] = useState<any>({
    updateDate: '',
    accountBasicInfo: null,
    chartData: null,
    hashtags: null,
    suitability: null,
    reportNo: 0,
  });
  const [itemPrice, setItemPrice] = useState(0);
  const [isTableOptionsGetNewDisabled, setIsTableOptionsGetNewDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    if (searchedAccountRef.current === '') setInputAccount('');
  }, [isShown]);
  useEffect(() => {
    if (isMounted && reportErrorCode !== '') {
      openServiceErrorModal(reportErrorCode);
    }
    setReportErrorCode('');
  }, [reportErrorCode]);

  function handleServiceDataSet(responseData: any) {
    const followValues: number[] = [];
    const followDates: string[] = [];
    const postValues: number[] = [];
    const postDates: string[] = [];
    const followerValues: number[] = [];
    const followerDates: string[] = [];
    const like_averageValues: number[] = [];
    const like_averageDates: string[] = [];
    const {
      update_date,
      search_date,
      info,
      follow,
      post,
      follower,
      like_average,
      exposed_hashtag,
      suitable_info,
      no,
    } = responseData;

    follow.forEach((e: { update_date: string; cnt: number }) => {
      followValues.push(e.cnt);
      followDates.push(e.update_date.substring(0, 10));
    });
    post.forEach((e: { update_date: string; cnt: number }) => {
      postValues.push(e.cnt);
      postDates.push(e.update_date.substring(0, 10));
    });
    follower.forEach((e: { update_date: string; cnt: number }) => {
      followerValues.push(e.cnt);
      followerDates.push(e.update_date.substring(0, 10));
    });
    like_average.forEach((e: { update_date: string; cnt: number }) => {
      like_averageValues.push(e.cnt);
      like_averageDates.push(e.update_date.substring(0, 10));
    });

    setServiceData({
      updateDate: update_date.replace(/T/, ''),
      accountBasicInfo: {
        searchDate: search_date,
        profileImg: info.profile_img,
        userName: info.user_name,
        fullName: info.full_name,
        biography: info.biography,
        post: info.post,
        follower: info.follower,
        follow: info.follow,
      },
      chartData: {
        follow: {
          values: followValues,
          dates: followDates,
        },
        follower: {
          values: followerValues,
          dates: followerDates,
        },
        post: {
          values: postValues,
          dates: postDates,
        },
        like_average: {
          values: like_averageValues,
          dates: like_averageDates,
        },
      },
      hashtags: exposed_hashtag.map(
        (e: { idx: number; hashtag: string; url: string }) => ({
          hashtag: e.hashtag,
          url: e.url,
        })
      ),
      suitability: onConvertPopularPostingExposureSuitability({
        postLevel: suitable_info.post_level,
        followerLevel: suitable_info.follower_level,
        activityLevel: suitable_info.activity_level,
        rankLevel: suitable_info.rank_level,
        rankWord: suitable_info.rank_word,
        workType: suitable_info.work_type,
      }),
      reportNo: no,
    });
  }
  const handleSearchButtonClick = async () => {
    if (inputAccount === '') return;
    try {
      if (
        inputAccount.includes('\\') ||
        inputAccount.includes('/') ||
        inputAccount.includes('?') ||
        inputAccount.includes('!') ||
        inputAccount.includes('@') ||
        inputAccount.includes('#')
      ) {
        throw Error;
      }
      const { status, order_info, price } = await checkKeywordIsOrdered(
        'account',
        inputAccount
      );
      setItemPrice(price);
      onModalOpen({
        onFirstButtonClick: async () => {
          const responseData = await getNewAccount(inputAccount);
          if (responseData) {
            if (responseData.status >= 400) {
              openServiceErrorModal(responseData.data.code);
            } else {
              searchedAccountRef.current = inputAccount;
              handleServiceDataSet(responseData);
              setIsTableOptionsGetNewDisabled(true);
            }
          }
        },
        onSecondButtonClick: async () => {
          const responseData = await getExist(order_info);
          searchedAccountRef.current = inputAccount;
          handleServiceDataSet(responseData);
          setIsTableOptionsGetNewDisabled(status === -1);
        },
        message:
          status === -1
            ? t('translation.ALREADY_PURCHASE_PRODUCTION_TITLE')
            : t('translation.PREMIUM_SERVICE'),
        subMessage: [
          status === -1
            ? t('translation.ALREADY_NEW_ANALYSIS_RESULT_PURCHASE_TITLE')
            : `${t(
                'translation.ALREADY_NEW_ANALYSIS_RESULT_PURCHASE_TEXT'
              )} ${addCommaToNumber(price)}${t(
                'translation.PREMIUM_SERVICE_TEXT2'
              )}`,
        ],
        isTwoButton: true,
        isFirstButtonDisabled: status === -1,
        isSecondButtonDisabled: order_info === '0',
        firstButtonText: t('translation.NEW_ANALYSIS_RESULT_INQUIRY'),
        secondButtonText: t('translation.ORIGIN_ANALYSIS_RESULT_INQUIRY'),
      });
    } catch (e) {
      onModalOpen({
        onFirstButtonClick: onModalClose,
        message: t('translation.NO_SEARCH_KEYWORD_TITLE'),
        subMessage: [
          t('translation.NO_SEARCH_KEYWORD_TEXT1'),
          t('translation.NO_SEARCH_KEYWORD_TEXT2'),
        ],
        firstButtonText: t('translation.OK_BUTTON'),
      });
    }
  };
  const handleGetNewChargedItemButtonClick = () => {
    if (isTableOptionsGetNewDisabled) return;
    onModalOpen({
      onFirstButtonClick: async () => {
        const responseData = await getNewAccount(inputAccount);
        if (responseData) {
          if (responseData.status >= 400) {
            openServiceErrorModal(responseData.data.code);
          } else {
            searchedAccountRef.current = inputAccount;
            handleServiceDataSet(responseData);
            setIsTableOptionsGetNewDisabled(true);
          }
        }
      },
      message: t('translation.PREMIUM_SERVICE'),
      subMessage: [
        `${t('translation.PREMIUM_SERVICE_TEXT1')} ${addCommaToNumber(
          itemPrice
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ],
      firstButtonText: t('translation.NEW_DATA_ANALYSIS_BUTTON'),
    });
  };
  const handleReportSave = async () => {
    const responseData = await checkIsReportDownloaded(
      'account',
      serviceData.reportNo
    );
    if (responseData) {
      const chartDates = {
        follow: `${serviceData.chartData?.follow.dates[0]} ~ ${
          serviceData.chartData?.follow.dates[
            serviceData.chartData.follow.dates.length - 1
          ]
        }`,
        post: `${serviceData.chartData?.post.dates[0]} ~ ${
          serviceData.chartData?.post.dates[
            serviceData.chartData.post.dates.length - 1
          ]
        }`,
        follower: `${serviceData.chartData?.follower.dates[0]} ~ ${
          serviceData.chartData?.follower.dates[
            serviceData.chartData.follower.dates.length - 1
          ]
        }`,
        likeAverage: `${serviceData.chartData?.like_average.dates[0]} ~ ${
          serviceData.chartData?.like_average.dates[
            serviceData.chartData.like_average.dates.length - 1
          ]
        }`,
      };
      const isReportDownload = true;
      const executedApi = (recipient = '') => {
        exportAccount(
          searchedAccountRef.current,
          serviceData.updateDate,
          recipient,
          serviceData.accountBasicInfo,
          serviceData.hashtags?.map((e: any) => e.hashtag),
          chartDates,
          {
            rankWord: serviceData.suitability?.rankWord,
            workType: serviceData.suitability?.workType,
          }
        );
      };
      const message = t('translation.PREMIUM_SERVICE');
      const subMessage = [
        `${t('translation.PREMIUM_SERVICE_TEXT3')} ${addCommaToNumber(
          responseData.price
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ];
      const firstButtonText = t('translation.REPORT_SAVE_BUTTON');
      onModalOpen({
        onFirstButtonClick: executedApi,
        isReportDownload,
        message,
        subMessage,
        firstButtonText,
      });
    }
  };

  const onConvertLanguage = () => {
    const originList = {
      ...DESCRIPTION,
      title: t('translation.ACCOUNT_ANALYSIS'),
    };

    const res = originList.info.map((obj: any) => {
      let title = '';
      let content = '';

      if (obj.title === '계정 분석이란?') {
        title = t('translation.ACCOUNT_ANALYSIS_TITLE1');
        content = t('translation.ACCOUNT_ANALYSIS_TITLE1_DESCRIPTION1');
      }

      if (obj.title === '추천 사용자') {
        title = t('translation.ACCOUNT_ANALYSIS_TITLE2');
        content = t('translation.ACCOUNT_ANALYSIS_TITLE2_DESCRIPTION1');
      }

      if (obj.title === '분석항목') {
        title = t('translation.ACCOUNT_ANALYSIS_TITLE3');
        content = t('translation.ACCOUNT_ANALYSIS_TITLE3_DESCRIPTION1');
      }

      return {
        ...obj,
        title,
        content,
      };
    });

    return {
      ...originList,
      info: res,
    };
  };

  if (getNewAccountItemState.isLoading || getExistChargedItemState.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {serviceData.updateDate &&
      serviceData.accountBasicInfo &&
      serviceData.chartData &&
      serviceData.hashtags &&
      serviceData.suitability ? (
        <MainTemplate
          hasSearchInput
          onInputChange={e => setInputAccount(e.target.value)}
          isAccount
          inputValue={inputAccount}
          onSearchClick={handleSearchButtonClick}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSearchButtonClick();
            }
          }}
        >
          <TableOptions
            isGetNewHave
            updateDate={serviceData.pdateDate}
            onGetNewClick={handleGetNewChargedItemButtonClick}
            onSaveReport={handleReportSave}
            isGetNewDisabled={isTableOptionsGetNewDisabled}
          />
          <FlexContainer>
            <FlexContainer width={480}>
              <SmallPublicBoxWithIcon
                key={nanoid()}
                iconName={dateInfo.iconName}
                width={dateInfo.width}
                height={dateInfo.height}
                value={serviceData.accountBasicInfo.searchDate.substring(0, 10)}
                label={t('translation.INQUIRY_DATE')}
              />
              <Gap height={40} />
              <PublicBox width={480} height={330}>
                <FlexContainer
                  style={{
                    margin: '15px 15px 0 15px',
                  }}
                  width={410}
                >
                  <img
                    style={{
                      width: '110px',
                      height: '110px',
                      marginTop: '11px',
                      borderRadius: '55px',
                    }}
                    src={serviceData.accountBasicInfo.profileImg}
                    alt="profile_img"
                  />
                  <FlexContainer
                    width={280}
                    style={{ flexDirection: 'column' }}
                  >
                    <PublicText
                      style={{
                        marginBottom: '10px',
                        width: '100%',
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                      value={serviceData.accountBasicInfo.userName}
                    />
                    <PublicText
                      style={{ marginBottom: '15px', fontSize: '18px' }}
                      value={serviceData.accountBasicInfo.fullName}
                    />
                    <PublicText
                      style={{
                        fontSize: '14px',
                        color: '#555555',
                        lineHeight: '20px',
                        height: '60px',
                        textOverflow: 'ellipsis',
                      }}
                      value={serviceData.accountBasicInfo.biography}
                    />
                  </FlexContainer>
                </FlexContainer>
                <div
                  style={{
                    position: 'absolute',
                    left: '0px',
                    bottom: '40px',
                    display: 'flex',
                    height: '80px',
                  }}
                >
                  <FlexContainer
                    style={{
                      flexDirection: 'column',
                      flexWrap: 'nowrap',
                      width: '160px',
                      height: '80px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PublicText
                      style={{
                        fontFamily: 'SCDream',
                        fontSize: '38px',
                        color: '#4A5BFD',
                      }}
                      value={convertNumberNotation(
                        serviceData.accountBasicInfo.post
                      )}
                    />
                    <PublicText
                      style={{ fontSize: '18px', color: '#555555' }}
                      value={t('translation.TABLE_POST')}
                    />
                  </FlexContainer>
                  <div
                    style={{
                      marginTop: '20px',
                      width: '2px',
                      height: '40px',
                      backgroundColor: '#CECECE',
                    }}
                  />
                  <FlexContainer
                    style={{
                      flexDirection: 'column',
                      flexWrap: 'nowrap',
                      width: '160px',
                      height: '80px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PublicText
                      style={{
                        fontFamily: 'SCDream',
                        fontSize: '38px',
                        color: '#2D7AFF',
                      }}
                      value={convertNumberNotation(
                        serviceData.accountBasicInfo.follower
                      )}
                    />
                    <PublicText
                      style={{ fontSize: '18px', color: '#555555' }}
                      value={t('translation.TABLE_FOLLOWER')}
                    />
                  </FlexContainer>
                  <div
                    style={{
                      marginTop: '20px',
                      width: '2px',
                      height: '40px',
                      backgroundColor: '#CECECE',
                    }}
                  />
                  <FlexContainer
                    style={{
                      flexDirection: 'column',
                      flexWrap: 'nowrap',
                      width: '160px',
                      height: '80px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PublicText
                      style={{
                        fontFamily: 'SCDream',
                        fontSize: '38px',
                        color: '#9068FF',
                      }}
                      value={convertNumberNotation(
                        serviceData.accountBasicInfo.follow
                      )}
                    />
                    <PublicText
                      style={{ fontSize: '18px', color: '#555555' }}
                      value={t('translation.TABLE_FOLLOW')}
                    />
                  </FlexContainer>
                </div>
              </PublicBox>
            </FlexContainer>
            <Gap width={40} />
            <FlexContainer width={940}>
              <PublicBoxWithTitle
                title={t('translation.FOLLOWING_COUNT')}
                width={450}
                height={220}
              >
                {serviceData.chartData.follow.dates.length === 0 &&
                serviceData.chartData.follow.values.length === 0 ? (
                  <NoDataCase top={90} />
                ) : (
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        top: '26px',
                        right: '18px',
                        color: '#A1A1A1',
                      }}
                    >
                      {`${serviceData.chartData.follow.dates[0]} ~ ${
                        serviceData.chartData.follow.dates[
                          serviceData.chartData.follow.dates.length - 1
                        ]
                      }`}
                    </div>
                    <AreaChart
                      tooltip={t('translation.FOLLOWING_COUNT')}
                      dates={serviceData.chartData.follow.dates}
                      values={serviceData.chartData?.follow.values}
                    />
                  </>
                )}
              </PublicBoxWithTitle>
              <PublicBoxWithTitle
                title={t('translation.POST_COUNT')}
                width={450}
                height={220}
              >
                {serviceData.chartData.post.dates.length === 0 &&
                serviceData.chartData.post.values.length === 0 ? (
                  <NoDataCase top={90} />
                ) : (
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        top: '26px',
                        right: '18px',
                        color: '#A1A1A1',
                      }}
                    >
                      {`${serviceData.chartData.post.dates[0]} ~ ${
                        serviceData.chartData.post.dates[
                          serviceData.chartData.post.dates.length - 1
                        ]
                      }`}
                    </div>
                    <AreaChart
                      tooltip={t('translation.POST_COUNT')}
                      dates={serviceData.chartData.post.dates}
                      values={serviceData.chartData?.post.values}
                    />
                  </>
                )}
              </PublicBoxWithTitle>
              <Gap height={40} />
              <PublicBoxWithTitle
                title={t('translation.FOLLOWER_COUNT')}
                width={450}
                height={220}
              >
                {serviceData.chartData.follower.dates.length === 0 &&
                serviceData.chartData.follower.values.length === 0 ? (
                  <NoDataCase top={90} />
                ) : (
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        top: '26px',
                        right: '18px',
                        color: '#A1A1A1',
                      }}
                    >
                      {`${serviceData.chartData.follower.dates[0]} ~ ${
                        serviceData.chartData.follower.dates[
                          serviceData.chartData.follower.dates.length - 1
                        ]
                      }`}
                    </div>
                    <AreaChart
                      tooltip={t('translation.FOLLOWER_COUNT')}
                      dates={serviceData.chartData.follower.dates}
                      values={serviceData.chartData?.follower.values}
                    />
                  </>
                )}
              </PublicBoxWithTitle>
              <PublicBoxWithTitle
                title={t('translation.LIKE_COUNT_AVERAGE')}
                width={450}
                height={220}
              >
                {serviceData.chartData.like_average.dates.length === 0 &&
                serviceData.chartData.like_average.values.length === 0 ? (
                  <NoDataCase top={90} />
                ) : (
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        top: '26px',
                        right: '18px',
                        color: '#A1A1A1',
                      }}
                    >
                      {`${serviceData.chartData.like_average.dates[0]} ~ ${
                        serviceData.chartData.like_average.dates[
                          serviceData.chartData.like_average.dates.length - 1
                        ]
                      }`}
                    </div>
                    <AreaChart
                      tooltip={t('translation.LIKE_COUNT_AVERAGE')}
                      dates={serviceData.chartData.like_average.dates}
                      values={serviceData.chartData?.like_average.values}
                    />
                  </>
                )}
              </PublicBoxWithTitle>
            </FlexContainer>
          </FlexContainer>
          <Gap height={40} />
          <FlexContainer>
            <PublicBoxWithTitle
              title={t('translation.POPULAR_POSTING_EXPOSURE_HASHTAG')}
              width={480}
              height={456}
            >
              {serviceData.hashtags.length === 0 ? (
                <NoDataCase top={200} />
              ) : (
                <ExposedHashtags tags={serviceData.hashtags} />
              )}
            </PublicBoxWithTitle>
            <PublicBoxWithTitle
              title={t('translation.POPULAR_POSTING_EXPOSURE_SUITABILITY')}
              width={940}
              height={456}
            >
              <AccountSuitability
                evaluation={serviceData.suitability.rankWord}
                guide={serviceData.suitability.workType}
              />
              <div id="detailSuitability">
                <DetailSuitability
                  post={serviceData.suitability.postLevel}
                  follower={serviceData.suitability.followerLevel}
                  activity={serviceData.suitability.activityLevel}
                />
              </div>
            </PublicBoxWithTitle>
          </FlexContainer>
        </MainTemplate>
      ) : (
        <S.Container>
          <S.Contents>
            <img
              style={{ width: '76px', height: '76px' }}
              src={LOGO.account}
              alt="logo"
            />
            <Gap height={28} />
            <PublicText
              style={{ fontSize: '40px', fontWeight: 'bold' }}
              value={t('translation.ACCOUNT_ANALYSIS')}
            />
            <Gap height={32} />
            <PublicSearchInput
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSearchButtonClick();
                }
              }}
              onSearchClick={handleSearchButtonClick}
              onInputChange={e => setInputAccount(e.target.value)}
              value={inputAccount}
              placeholder={t('translation.INPUT_PLACEHOLDER_ACCOUNT')}
              style={{
                width: '748px',
                height: '70px',
                borderRadius: '10px',
                fontSize: '20px',
                inputWidth: '638px',
              }}
            />
            <Gap height={70} />
            <S.Infos>
              {onConvertLanguage().info.map((info, i) => {
                const titles: any[] = info.title.split('/');
                const texts: any[] = info.content.split('/');
                return (
                  <PublicBox
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '30px 10px',
                    }}
                    width={236}
                    height={250}
                    key={nanoid()}
                  >
                    <S.IconWrapper>
                      <img
                        src={info.iconName}
                        alt="icon"
                        style={{ width: '100%' }}
                      />
                    </S.IconWrapper>
                    {titles.map(text => (
                      <PublicText
                        key={nanoid()}
                        value={text}
                        style={{ fontWeight: 'bold', fontSize: '22px' }}
                      />
                    ))}
                    <Gap height={10} />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: i === 2 ? 'flex-start' : 'center',
                      }}
                    >
                      {texts.map(text => (
                        <PublicText
                          key={nanoid()}
                          style={{ textAlign: 'center' }}
                          value={text}
                        />
                      ))}
                    </div>
                  </PublicBox>
                );
              })}
            </S.Infos>
          </S.Contents>
        </S.Container>
      )}
      <ReportSpinner />
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </>
  );
};
export default Account;
