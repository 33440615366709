import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 650px;
  height: 700px;
  border-radius: 10px 0 0 10px;
`;
export const Pickers = styled.div`
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
`;
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 62px;
  padding-bottom: 60px;
  width: 650px;
  height: 700px;
  background-color: #4a5bfd;
`;
export const Message = styled.img`
  height: 235px;
`;
export const Item = styled.img`
  width: auto;
  height: 300px;
`;
export const ArrowLeft = styled.img`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
`;
export const ArrowRight = styled.img`
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
`;
