import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { Search_button as Button } from '@assets/svgs';
import * as S from './PublicSearchInput.style';

interface Props {
  style: Record<string, string>;
  placeholder: string;
  onSearchClick?: () => void;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: any) => void;
  value?: string;
}

export const PublicSearchInput = ({
  style,
  placeholder,
  onSearchClick,
  onInputChange,
  onKeyPress,
  value,
}: Props) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  return (
    <S.PublicSearchInput
      style={{
        width: style.width,
        height: style.height,
        backgroundColor: style.backgroundColor,
        border: style.border,
        borderRadius: style.borderRadius,
      }}
    >
      <S.Input
        style={{
          backgroundColor: style.backgroundColor,
          fontSize: style.fontSize,
          width: style.inputWidth,
        }}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        onChange={onInputChange}
        value={value}
      />
      <S.ButtonWrapper onClick={onSearchClick}>
        <Button
          color={isButtonHovered ? '#8D97FF' : '#a0a5b7'}
          onMouseEnter={() => setIsButtonHovered(true)}
          onMouseLeave={() => setIsButtonHovered(false)}
        />
      </S.ButtonWrapper>
    </S.PublicSearchInput>
  );
};
