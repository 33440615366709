import { ButtonHTMLAttributes } from 'react';
import * as S from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  style: Record<string, string>;
  value: string;
}

export const PublicButton = ({ style, value, ...props }: Props) => {
  return (
    <S.PublicButton style={style} {...props}>
      {value}
    </S.PublicButton>
  );
};
