export const errorCodes = {
  PARAMETER_REQUIRED: '000101',
  LOGIN_REQUIRED: '000201',
  LOGIN_USER_NOT_FOUND: '000202',
  LOGIN_FAILED: '000203',
  LOGIN_EXPIRED: '000204',
  TOKEN_FALSIFICATION: '000205',
  NOT_AUTHENTICATION_EMAIL: '000206',
  NEED_EMAIL_AUTENTICATION: '000206',
  UNACTIVE_USER: '000207',
  NOT_ADMIN_USER: '000208',
  NOT_UNACTIVE_USER: '000209',
  IS_ACTIVE_USER: '000210',
  EXPIRATION_EMAIL_AUTH: '000211',
  MIN_LENGTH: '000301',
  MAX_LENGTH: '000302',
  NOT_HASHTAG: '000303',
  INCLUDE_BLANK: '000304',
  ALPHANUMBERIC: '000305',
  NOT_INSTAGRAM_USERNAME: '000306',
  NOT_NUMBER_ONLY: '000307',
  NOT_SATISFY_ID_FORM: '000308',
  NOT_SATISFY_PW_FORM: '000309',
  NOT_SATISFY_NAME_FORM: '000310',
  EMPTY_VALUE: '000401',
  ID_EXSISTS: '000402',
  PASSWORD_DIFFER: '000403',
  NAME_EXISTS: '000404',
  LESS_POINT: '000500',
  NON_EXISTENT_SERVICE: '000501',
  UNACTIVE_SERVICE: '000502',
  THIRDPARTY_PARSING: '000503',
  INSTA_MEDIA_NOT_FOUND: '100001',
  INSTA_USER_NOT_FOUND: '100002',
  INSTA_CHECKPOINT_REQUIRED: '100101',
  INSTA_CHALLENGE_REQUIRED: '100102',
  INSTA_SENTRY_BLOCK: '100103',
  INSTA_FEEDBACK_REQUIRED: '100104',
  INSTA_PERMANENT_BLOCK: '100105',
  INSTA_TWO_FACTOR_REQUIRED: '100106',
  UNKNOWN_ERROR: '100107',
  CONDITION_NOT_MET: '100108',
  EXCESS_ACC_SOCIAL: '100109',
};
