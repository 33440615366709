import type { FC } from 'react';
import * as S from './styles';

interface Props {
  width: number;
  height: number;
  children: React.ReactNode;
}

export const SmallPublicBox: FC<Props> = ({ width, height, children }) => {
  return (
    <S.SmallPublicBox width={width} height={height}>
      {children}
    </S.SmallPublicBox>
  );
};
