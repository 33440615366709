import { useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import {
  Bulb,
  PaginationNextButton,
  PaginationPrevButton,
  SuitabilityBad,
  SuitabilityNormal,
  SuitabilityGood,
} from '@assets/svgs/AccountAnalysis';
import * as S from './Account.style';

// 인기게시물 노출 해시태그 - 페이지네이션
interface PaginationProps {
  currentPage: number;
  tagsPerPage: number;
  totalTags: number | undefined;
  handlePaginateNext: () => void;
  handlePaginatePrev: () => void;
}
const Pagination = ({
  currentPage,
  tagsPerPage,
  totalTags,
  handlePaginateNext,
  handlePaginatePrev,
}: PaginationProps) => {
  const pageNumbers = [];
  if (typeof totalTags === 'number') {
    for (let i = 1; i <= Math.ceil(totalTags / tagsPerPage); i += 1) {
      pageNumbers.push(i);
    }
  }
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pageNumbers.length;

  return (
    <S.Pagination isFirstPage={isFirstPage} isLastPage={isLastPage}>
      <span className="page">
        <span>{currentPage}</span> / {pageNumbers.length}
      </span>
      <PaginationPrevButton handlePaginatePrev={handlePaginatePrev} />
      <PaginationNextButton handlePaginateNext={handlePaginateNext} />
    </S.Pagination>
  );
};

// 인기게시물 노출 해시태그 - 해시태그들
interface HashtagsProps {
  tags: {
    hashtag: string;
    url: string;
  }[];
  indexOfFirstTag: number;
}
const Hashtags = ({ tags, indexOfFirstTag }: HashtagsProps) => (
  <S.Hashtags>
    {tags?.map(
      (
        tag: {
          hashtag: string;
          url: string;
        },
        i: number
      ) => (
        <S.Hashtag key={nanoid()}>
          <a target="_blank" rel="noopener noreferrer" href={tag.url}>{`${
            indexOfFirstTag + i + 1
          }. ${tag.hashtag}`}</a>
        </S.Hashtag>
      )
    )}
  </S.Hashtags>
);

// 인기게시물 노출 해시태그
interface ExposedHashtagsProps {
  tags: {
    hashtag: string;
    url: string;
  }[];
}
export const ExposedHashtags = ({ tags }: ExposedHashtagsProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tagsPerPage] = useState(10);
  const indexOfLastTag = currentPage * tagsPerPage;
  const indexOfFirstTag = indexOfLastTag - tagsPerPage;
  const currentTags = tags.slice(indexOfFirstTag, indexOfLastTag);

  return (
    <S.ExposedHashtags>
      <Hashtags tags={currentTags} indexOfFirstTag={indexOfFirstTag} />
      <Pagination
        currentPage={currentPage}
        tagsPerPage={tagsPerPage}
        totalTags={tags?.length}
        handlePaginateNext={() => {
          if (currentPage < tags.length / tagsPerPage) {
            setCurrentPage(currentPage + 1);
          }
        }}
        handlePaginatePrev={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          }
        }}
      />
    </S.ExposedHashtags>
  );
};

// 인기게시물 노출적합도의
// 평가 및 작업 추천
interface AccountSuitabilityProps {
  evaluation?: string;
  guide?: string;
}
export const AccountSuitability = ({
  evaluation,
  guide,
}: AccountSuitabilityProps) => (
  <S.AccountSuitability>
    <div className="suitability">{evaluation}</div>
    <div className="commentContainer">
      <Bulb />
      <p>{guide}</p>
    </div>
  </S.AccountSuitability>
);

// 인기게시물 노출적합도의
// 포스트 수, 팔로워 수 , 활동량
interface DetailSuitabilityProps {
  post: string;
  follower: string;
  activity: string;
}
export const DetailSuitability = ({
  post,
  follower,
  activity,
}: DetailSuitabilityProps) => {
  const { t } = useTranslation();

  const GoodBlock = () => (
    <div className="content">
      <div className="img">
        <SuitabilityGood />
      </div>
      <div>
        <span />
        <span>{t('translation.AMBIGUOUS')}</span>
        <span className="stress">{t('translation.SATISFIED')}</span>
      </div>
    </div>
  );
  const NormalBlock = () => (
    <div className="content">
      <div className="img">
        <SuitabilityNormal />
      </div>
      <div>
        <span>{t('translation.INSUFFICIENT')}</span>
        <span className="stress">{t('translation.AMBIGUOUS')}</span>
        <span>{t('translation.SATISFIED')}</span>
      </div>
    </div>
  );
  const BadBlock = () => (
    <div className="content">
      <div className="img">
        <SuitabilityBad />
      </div>
      <div>
        <span className="stress">{t('translation.INSUFFICIENT')}</span>
        <span>{t('translation.AMBIGUOUS')}</span>
        <span>{t('translation.SATISFIED')}</span>
      </div>
    </div>
  );

  const postBlock =
    post === t('translation.SATISFIED') ? (
      <GoodBlock />
    ) : post === t('translation.AMBIGUOUS') ? (
      <NormalBlock />
    ) : (
      <BadBlock />
    );
  const followerBlock =
    follower === t('translation.SATISFIED') ? (
      <GoodBlock />
    ) : follower === t('translation.AMBIGUOUS') ? (
      <NormalBlock />
    ) : (
      <BadBlock />
    );
  const activityBlock =
    activity === t('translation.SATISFIED') ? (
      <GoodBlock />
    ) : activity === t('translation.AMBIGUOUS') ? (
      <NormalBlock />
    ) : (
      <BadBlock />
    );
  return (
    <S.DetailSuitability>
      <div className="detailContainer">
        <p className="title">{t('translation.POST_COUNT')}</p>
        {postBlock}
      </div>
      <div className="detailContainer">
        <p className="title">{t('translation.FOLLOWER_COUNT')}</p>
        {followerBlock}
      </div>
      <div className="detailContainer">
        <p className="title">{t('translation.ACTIVITY_AMOUNT')}</p>
        {activityBlock}
      </div>
    </S.DetailSuitability>
  );
};

// 데이터가 없는 케이스에서 표시
export const NoDataCase = ({ top }: any) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        left: '50%',
        top: `${top}px`,
        width: '280px',
        transform: 'translateX(-50%)',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '5px',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        {/* 분석 결과가 없습니다 */}
        {t('translation.TABLE_NO_LIST_TITLE')}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p>
          {/* 해당하는 데이터가 없거나 수집되지 않아 */}

          {t('translation.TABLE_NO_LIST_TEXT1')}
        </p>
        <p>
          {/* 검색 및 분석할 수 없습니다. */}
          {t('translation.TABLE_NO_LIST_TEXT2')}
        </p>
      </div>
    </div>
  );
};
