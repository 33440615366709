import styled from 'styled-components';

export const LoginForm = styled.form`
  padding: 85px 75px 0;
  width: 100%;
`;
export const Logo = styled.div`
  margin: 0 72px 30px;
  width: 206px;
  height: auto;
`;
export const Inputs = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  & > input:first-child {
    margin-bottom: 16px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 26px 66px 44px;
`;
export const Button = styled.button`
  border: 0;
  background-color: #fff;
  cursor: pointer;
`;

export const HelpButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;

export const PublicInput = styled.input`
  outline: none;
  border: none;
  border: 1px solid #cecece;
  color: #000;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a1a1;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a1a1a1;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #a1a1a1;
  }
`;
