import type { FC } from 'react';
import * as S from './styles';

interface Props {
  width: number;
  height: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const PublicBox: FC<Props> = ({ width, height, children, style }) => {
  return (
    <S.PublicBox width={width} height={height} style={style}>
      {children}
    </S.PublicBox>
  );
};
