export const Bulb: React.FC<SVGStyle> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="80"
    height="80"
    viewBox="0 0 80 80"
  >
    <defs>
      <filter
        id="타원_21"
        x="0"
        y="0"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="5" result="blur" />
        <feFlood floodColor="#4a5bfd" floodOpacity="0.4" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="그룹_3732" data-name="그룹 3732" transform="translate(-407 -1314)">
      <g id="그룹_3737" data-name="그룹 3737">
        <g transform="matrix(1, 0, 0, 1, 407, 1314)" filter="url(#타원_21)">
          <circle
            id="타원_21-2"
            data-name="타원 21"
            cx="25"
            cy="25"
            r="25"
            transform="translate(15 12)"
            fill="#4a5bfd"
          />
        </g>
        <g id="그룹_3730" data-name="그룹 3730" transform="translate(435 1335)">
          <path
            id="패스_1522"
            data-name="패스 1522"
            d="M1615.585,721.842a4.51,4.51,0,0,1-4.53-3.912.89.89,0,1,1,1.755-.294,2.8,2.8,0,0,0,5.55,0,.89.89,0,0,1,1.755.294A4.509,4.509,0,0,1,1615.585,721.842Z"
            transform="translate(-1603.584 -689.842)"
            fill="#fff"
          />
          <path
            id="패스_1523"
            data-name="패스 1523"
            d="M1584.936,631.12a.8.8,0,0,1-.406-.111,12.514,12.514,0,0,1-6.115-10.817,12.007,12.007,0,1,1,24,0,12.512,12.512,0,0,1-6.116,10.817.815.815,0,0,1-1.132-.331.883.883,0,0,1,.319-1.177,10.769,10.769,0,0,0,5.266-9.309,10.342,10.342,0,1,0-20.673,0,10.771,10.771,0,0,0,5.266,9.309.883.883,0,0,1,.319,1.177A.827.827,0,0,1,1584.936,631.12Z"
            transform="translate(-1578.414 -607.783)"
            fill="#fff"
          />
          <path
            id="패스_1524"
            data-name="패스 1524"
            d="M1626.067,642.508a1.053,1.053,0,0,1-2.1,0l-.276-5.7a1.329,1.329,0,0,1,2.654,0Zm-1.051,1.9a1.327,1.327,0,1,1-1.327,1.326A1.34,1.34,0,0,1,1625.016,644.413Z"
            transform="translate(-1613.016 -628.727)"
            fill="#fff"
          />
          <path
            id="패스_1525"
            data-name="패스 1525"
            d="M1612,702.581h-7.3a2.141,2.141,0,0,1-1.991-2.073l-.24-2.954a2.25,2.25,0,0,1,.455-1.575,1.708,1.708,0,0,1,1.332-.662h8.185a1.708,1.708,0,0,1,1.331.662,2.251,2.251,0,0,1,.455,1.575l-.24,2.954A2.14,2.14,0,0,1,1612,702.581Zm-7.74-5.485a.22.22,0,0,0-.18.082.289.289,0,0,0-.054.213l.24,2.954a.5.5,0,0,0,.439.458h7.3a.5.5,0,0,0,.44-.458l.24-2.954a.291.291,0,0,0-.055-.212.219.219,0,0,0-.18-.082Z"
            transform="translate(-1596.352 -673.75)"
            fill="#fff"
          />
        </g>
        <path
          id="패스_1526"
          data-name="패스 1526"
          d="M1614.075,708.13h-9.887a.77.77,0,0,1,0-1.525h9.887a.77.77,0,0,1,0,1.525Z"
          transform="translate(-1162.142 652.963)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
