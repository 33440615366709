import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import Info from '@assets/images/Manage_infoicon@2x.png';
import { PublicThirdButton } from '@atoms/buttons';
import { PublicText } from '@atoms/texts';
import { Modal } from '@atoms/utils';
import { SubTemplate } from '@templates/SubTemplate';
import { useGetAccountInfoQuery } from '@features/api';
import { useTokenRefresh, useModal } from '@hooks';
import * as S from './Manage.style';

interface SectionProps {
  title: string;
  hasIcon?: boolean;
  children?: React.ReactNode;
}

const Section: FC<SectionProps> = ({ title, hasIcon, children }) => {
  const { t } = useTranslation();
  const [isTooltipShow, setIsTooltipShow] = useState<boolean>(false);
  return (
    <S.Section>
      <S.SectionTitle>
        <PublicText
          value={title}
          style={{
            marginRight: '4px',
            fontWeight: 'bold',
            fontSize: '24px',
          }}
        />
        {hasIcon && (
          <>
            <img
              style={{
                width: '24px',
                height: '24px',
              }}
              src={Info}
              alt="info"
              onMouseEnter={() => setIsTooltipShow(true)}
              onMouseLeave={() => setIsTooltipShow(false)}
            />
            {isTooltipShow && (
              <PublicText
                value={`${t(
                  'translation.MYPAGE_ACCOUNT_SETTING_COMPANY_INFORMATION_TOOL_TIP'
                )}`}
                style={{
                  marginLeft: '4px',
                  fontSize: '12px',
                  color: '#555555',
                }}
              />
            )}
          </>
        )}
      </S.SectionTitle>
      {children}
    </S.Section>
  );
};

const Manage: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { isShown, modalContent, onModalOpen, onModalClose } = useModal();

  const { refresh } = useTokenRefresh();

  const manageInfo = useGetAccountInfoQuery({});

  useEffect(() => {
    refresh();
  }, []);

  const handleConnectToKakaotalk = () => {
    onModalOpen({
      onFirstButtonClick: () => {
        window.open('http://pf.kakao.com/_pzfsb/chat');
      },
      message: t('translation.COUSTOMER_SERVICE_CENTER_TITLE'),
      subMessage: [t('translation.COUSTOMER_SERVICE_CENTER_TEXT')],
      firstButtonText: t('translation.OK_BUTTON'),
    });
  };

  return (
    <>
      <SubTemplate title={t('translation.MYPAGE_ACCOUNT_SETTING')}>
        {manageInfo.isSuccess && (
          <S.Contents>
            <Section
              title={t('translation.MYPAGE_ACCOUNT_SETTING_MY_INFORMATION')}
            >
              <S.SectionDetail>
                <PublicText
                  value={t('translation.MYPAGE_ACCOUNT_SETTING_NICKNAME')}
                  style={{
                    width: '140px',
                    fontWeight: 'bold',
                  }}
                />
                <PublicText
                  value={manageInfo.data.name}
                  style={{
                    marginRight: '30px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                />
                <PublicThirdButton
                  value={t(
                    'translation.MYPAGE_ACCOUNT_SETTING_NICKNAME_CHANGE_BUTTON'
                  )}
                  onClick={handleConnectToKakaotalk}
                />
              </S.SectionDetail>
              <S.SectionDetail>
                <PublicText
                  value={t('translation.MYPAGE_ACCOUNT_SETTING_ID')}
                  style={{
                    width: '140px',
                    fontWeight: 'bold',
                  }}
                />
                <PublicText
                  value={manageInfo.data.id}
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                />
              </S.SectionDetail>
              <S.SectionDetail>
                <PublicText
                  value={t('translation.MYPAGE_ACCOUNT_SETTING_PASSWORD')}
                  style={{
                    width: '140px',
                    fontWeight: 'bold',
                  }}
                />
                <PublicThirdButton
                  value={t('translation.MYPAGE_ACCOUNT_SETTING_PASSWORD')}
                  onClick={handleConnectToKakaotalk}
                />
              </S.SectionDetail>
              <S.SectionDetail>
                <PublicText
                  value={t('translation.MYPAGE_ACCOUNT_SETTING_EMAIL')}
                  style={{
                    width: '140px',
                    fontWeight: 'bold',
                  }}
                />
                <PublicText
                  value={manageInfo.data.email}
                  style={{
                    marginRight: '30px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                />
                <PublicThirdButton
                  value={t(
                    'translation.MYPAGE_ACCOUNT_SETTING_EMAIL_CHANGE_BUTTON'
                  )}
                  onClick={handleConnectToKakaotalk}
                />
              </S.SectionDetail>
              <S.SectionDetail>
                <PublicText
                  value={t('translation.MYPAGE_ACCOUNT_SETTING_USER_LEAVE')}
                  style={{
                    width: '140px',
                    fontWeight: 'bold',
                  }}
                />
                <PublicThirdButton
                  value={t('translation.MYPAGE_ACCOUNT_SETTING_USER_LEAVE')}
                  onClick={handleConnectToKakaotalk}
                />
              </S.SectionDetail>
            </Section>
            <Section
              title={t(
                'translation.MYPAGE_ACCOUNT_SETTING_COMPANY_INFORMATION'
              )}
              hasIcon
            >
              <S.SectionDetail>
                <PublicText
                  value={t('translation.MYPAGE_ACCOUNT_SETTING_AFFILIATION')}
                  style={{
                    width: '140px',
                    fontWeight: 'bold',
                  }}
                />
                <PublicText
                  value={manageInfo.data.corporation}
                  style={{
                    marginRight: '30px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                />
              </S.SectionDetail>
              <S.SectionDetail>
                <PublicText
                  value={t(
                    'translation.MYPAGE_ACCOUNT_SETTING_BUSINESS_REGISTRATION_NUMBER'
                  )}
                  style={{
                    width: '140px',
                    fontWeight: 'bold',
                  }}
                />
                <PublicText
                  value={manageInfo.data.bf_number}
                  style={{
                    marginRight: '30px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                />
              </S.SectionDetail>
            </Section>
          </S.Contents>
        )}
      </SubTemplate>
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </>
  );
};
export default Manage;
