import ClipLoader from 'react-spinners/ClipLoader';

export const Spinner = () => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <ClipLoader color="#4A5BFD" speedMultiplier={0.5} size={150} />
      </div>
    </div>
  );
};
