import styled from 'styled-components';

export const SCDMediumText = styled.p<TextStyle>`
  font-family: 'SCDream';
  font-weight: 500;
  ${({ theme, desktopFontSize }) =>
    desktopFontSize &&
    theme.media.desktop`font-size: ${theme.fontSize[desktopFontSize]}px;`}
  ${({ theme, tabletFontSize }) =>
    tabletFontSize &&
    theme.media.tablet`font-size: ${theme.fontSize[tabletFontSize]}px;`}
  ${({ theme, mobileFontSize }) =>
    mobileFontSize &&
    theme.media.mobile`font-size: ${theme.fontSize[mobileFontSize]}px;`}
  color: ${({ color, theme }) => theme.color[color as string]};
`;
